import { useCallback, useEffect, useRef, useState } from "react";

export interface IMessage {
    lobbyCode?: string,
    type: string,
    value?: any,
}

function useWebSocket(wsUrl: string, onMessageCallback: (message: IMessage) => void) {
    const [status, setStatus] = useState("connecting");
    const websocket = useRef<WebSocket | null>(null);
    const retry = useRef(5);

    useEffect(() => {
        openConnection();
        return () => {
            if (websocket.current) {
                websocket.current.close();
            }
        }
    }, [wsUrl]);

    const openConnection = useCallback((onOpenCallback?: () => void) => {
        const socket = new WebSocket(wsUrl);
        socket.onopen = function () {
            retry.current = 5;
            setStatus("connected");
            socket.onmessage = onMessage;
            socket.onclose = onClose;

            if (onOpenCallback) {
                onOpenCallback();
            }
        }
        websocket.current = socket
    }, [wsUrl]);

    const onMessage = useCallback((e: MessageEvent<any>) => {
        const parsedMessage = JSON.parse(e.data);
        onMessageCallback(parsedMessage);
    }, [onMessageCallback]);

    const onClose = useCallback((e: CloseEvent) => {
        setStatus("closed")
        setTimeout(() => {
            if (retry.current > 0) {
                retry.current--;
                openConnection();
            }
        }, 1000);
    }, []);

    const sendMessage = (message: IMessage) => {
        if (websocket.current) {
            if (!isOpen) {
                openConnection(() => {
                    sendMessage(message);
                });
            } else {
                websocket.current.send(JSON.stringify(message))
            }
        }
    }

    return { isConnected: status === "connected", status, sendMessage }
}

function isOpen(ws: WebSocket) { return ws.readyState === ws.OPEN }

export default useWebSocket;