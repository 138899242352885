import { MovementTile, MapTile, IPlayerStartingPosition } from "../models/map";

export const demo_map: MovementTile[][] = [
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 2, 0, 0, 0],
    [0, 0, 2, 0, 2, 0, 0],
    [0, 0, 0, 2, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
]

export const demo_map_tiles: MapTile[][] = [
    // [MapTile.floor1]
    [MapTile.floor1, MapTile.floor2, MapTile.floor2, MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor1],
    [MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor1, MapTile.floor1, MapTile.floor2, MapTile.floor1],
    [MapTile.floor2, MapTile.floor1, MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor1, MapTile.floor1],
    [MapTile.floor1, MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor2],
    [MapTile.floor1, MapTile.floor2, MapTile.floor2, MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor1],
    [MapTile.floor2, MapTile.floor1, MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor1, MapTile.floor1],
    [MapTile.floor1, MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor2, MapTile.floor1, MapTile.floor2],
]

export const demo_starting_pos: IPlayerStartingPosition[] = [
    { playerId: "P1", position: { x: 3, z: 2 } },
    { playerId: "P2", position: { x: 2, z: 3 } },
    { playerId: "P3", position: { x: 4, z: 3 } },
    { playerId: "P4", position: { x: 3, z: 4 } },
]