import { observer } from "mobx-react-lite"
import { ICard } from "../../../models/elementsclash";
import "../../../styles/games/elements_clash/clashcard.scss";

interface IProps {
  card: ICard,
  clash?: boolean,
  ripped?: boolean
}

const ClashCard = ({ card, clash = false, ripped = false }: IProps) => {
  return (
    <div className={`clash-card ${clash ? "fight" : ""} ${ripped ? "ripped" : ""}`}>
      <div className="content">
        {/* Top */}
        <div className="top">
          <img className="card-image" alt={card.name} src={`${process.env.PUBLIC_URL}/assets/images/cards/creatures/${card.artwork}`} />
        </div>
        {/* Bottom */}
        <div className="bottom">
          <img className="card-image" alt={card.name} src={`${process.env.PUBLIC_URL}/assets/images/cards/creatures/${card.artwork}`} />
        </div>
        <div className="info">
          {card.power}
        </div>
      </div>
    </div>
  );
}

export default observer(ClashCard);