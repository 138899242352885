import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { Utility } from "../../../functions/common";
import useDrag from "../../../hooks/usedrag";
import { useStore } from "../../../hooks/usestore";
import { Origin } from "../../../models/draggable";
import { ICard } from "../../../models/elementsclash";
import Card from "../components/card";
import "../../../styles/games/elements_clash/hand.scss";

interface IProps {
    cards: ICard[],
    otherPlayer?: boolean
}

const Hand = ({ cards, otherPlayer }: IProps) => {
    const { elementsClashStore: { fieldCards, setFieldCards, playerHand, setPlayerHand, grabbedCard, setGrabbedCard } } = useStore();
    const handRef = useRef<HTMLDivElement>(null!);

    useDrag(handRef.current, [grabbedCard], {
        onPointerUp: checkHand,
    })

    function checkHand(e: PointerEvent) {
        if (grabbedCard) {
            let hoveredCard = (e.target as HTMLElement).getAttribute("custom-name");
            if (grabbedCard.origin === Origin.field) {
                if (hoveredCard) {
                    let index = playerHand.findIndex(x => x.name === hoveredCard);
                    let newHand = playerHand.filter(x => x.name !== grabbedCard.object.name);
                    setPlayerHand([...newHand.slice(0, index), grabbedCard.object, ...newHand.slice(index)]);
                } else {
                    setPlayerHand([...playerHand, grabbedCard.object]);
                }
                setFieldCards(fieldCards.playerField.filter(x => x.name !== grabbedCard.object.name), fieldCards.otherPlayerField);
                setGrabbedCard(null);
            } else if (grabbedCard.origin === Origin.hand) {
                if (hoveredCard) {
                    let index = playerHand.findIndex(x => x.name === hoveredCard);
                    let newHand = playerHand.filter(x => x.name !== grabbedCard.object.name);
                    setPlayerHand([...newHand.slice(0, index), grabbedCard.object, ...newHand.slice(index)]);
                }
            }
        }
    }

    return (
        <div ref={handRef} className={`${otherPlayer ? "other-player " : "player "}hand`} >
            {
                cards.map((x, index) => {
                    let rotation = Utility.calcCardHandRotation(index, cards.length);
                    return <Card key={x.name} card={x} draggable={!otherPlayer} flipped={otherPlayer} rotation={rotation} origin={Origin.hand} />
                })
            }
        </div>
    )
}

export default observer(Hand);