import { useCallback, useEffect, useRef } from "react";
import { useStore } from "../../hooks/usestore";
import * as THREE from "three";
import { observer } from "mobx-react-lite";
import { CanvasElement3D } from "../../models/canvaselement3d";

const Raycaster = () => {
    const { canvasStore: { scene, camera, elements } } = useStore();
    const elementsRef = useRef<CanvasElement3D[]>([]);
    useEffect(() => {
        if (scene && camera) {
            window.addEventListener('pointerdown', onPointerDown);
            return () => {
                window.removeEventListener('pointerdown', onPointerDown);
            }
        }
    }, [scene, camera])
    useEffect(() => {
        elementsRef.current = elements;
    }, [elements])
    const onPointerDown = useCallback((e: PointerEvent) => {
        e.preventDefault();
        if (e.buttons === 1) {
            if (scene && camera) {
                const raycaster = new THREE.Raycaster();
                const mouse = new THREE.Vector2();
                mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
                mouse.y = - (e.clientY / window.innerHeight) * 2 + 1;

                raycaster.setFromCamera(mouse, camera);
                const intersects = raycaster.intersectObjects(scene.children, true);
                if (intersects.length > 0) {
                    intersects.forEach(x => {
                        const object = x.object;
                        const element = elementsRef.current.find(x => x.id === object.uuid);
                        if (element && element.onClick) {
                            element.onClick();
                        }
                    })
                }
            }
        }
    }, [scene, camera])
    return (
        <></>
    )
}

export default observer(Raycaster);