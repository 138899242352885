import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/usestore";
import round1 from "../../assets/images/stella/round_1.png";
import round2 from "../../assets/images/stella/round_2.png";
import round3 from "../../assets/images/stella/round_3.png";
import round4 from "../../assets/images/stella/round_4.png";
import roundBg1 from "../../assets/images/stella/round_bg_1.png";
import roundBg2 from "../../assets/images/stella/round_bg_2.png";
import roundBg3 from "../../assets/images/stella/round_bg_3.png";

const RoundInfo = observer(() => {
  const { stellaStore: { round } } = useStore();

  return (
    <div className="rounds-info">
      <div className={`coin ${round > 1 ? "flip" : ""}`}>
        <img className={round === 1 ? "current" : ""} src={round1} />
        <img className={round === 1 ? "current" : ""} src={roundBg1} />
      </div>
      <div className={`coin ${round > 2 ? "flip" : ""}`}>
        <img className={round === 2 ? "current" : ""} src={round2} />
        <img className={round === 2 ? "current" : ""} src={roundBg2} />
      </div>
      <div className={`coin ${round > 3 ? "flip" : ""}`}>
        <img className={round === 3 ? "current" : ""} src={round3} />
        <img className={round === 3 ? "current" : ""} src={roundBg3} />
      </div>
      <div className="coin">
        <img className={round === 4 ? "current" : ""} src={round4} />
      </div>
    </div >
  )
})

export default RoundInfo;