import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useState, useEffect, CSSProperties } from "react";
import { invertColor } from "../../functions/utility";
import { useKeyDown } from "../../hooks/usekeydown";
import { IMessage } from "../../hooks/usewebsocket";
import "../../styles/games/stella_updated/info.scss";

interface INotification {
  id?: string,
  color: string,
  message: string,
}

const Info = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "notification_stella", handleNotification);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "notification_stella");
      }
    }
  }, [])

  function handleNotification(message: IMessage) {
    console.log("Notification received")
    setNotifications(prev => {
      let upd = [...prev];
      upd.push({ ...message.value, id: _.uniqueId() });
      return upd;
    })
  }

  return <section className="info">
    <div className="notifications">
      {notifications.map(x => <Notification key={x.id} notification={x} />)}
    </div>
  </section> 
}

export default observer(Info);

interface INotificationProps {
  notification: INotification,
}

const Notification = ({ notification }: INotificationProps) => {
  return (
    <div className="notification" style={{ "--notification-color": notification.color, "--text-color": invertColor(notification.color, true) } as CSSProperties}>
      {notification.message}
    </div>
  )
}