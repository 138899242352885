import { observer } from "mobx-react-lite";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hexToComplimentary, invertColor, newShade } from "../functions/utility";
import { useKeyDown } from "../hooks/usekeydown";
import { useStore } from "../hooks/usestore";
import { ICard, IChatMessage, IPlayer, Phase } from "../models/stella";
import useGesture, { IPosition } from "../hooks/usegesture";
import useAnimationFrame from "../hooks/useanimationframe";
import { easeInOut, easeOut, lerp } from "../functions/common";
import Demo from "../assets/images/stella/demo.png";

import { IMessage } from "../hooks/usewebsocket";
// import "../styles/pages/stella.scss";
import _ from "lodash";
import Notifications from "../games/stella/notification";
import Chat from "../games/stella/chat";
import Helper from "../games/stella/helper";
import Actions from "../games/stella/actions";
import Players from "../games/stella/players";
import Board from "../games/stella/board";
import Steps from "../games/stella/steps";
import RoundInfo from "../games/stella/roundinfo";
import Word from "../games/stella/word";
import Rounds from "../games/stella/rounds";

const Stella = () => {
  const { lobbyStore: { setMyPlayerID }, stellaStore: { myPlayer, players, setupStella, updatePlayer, updatePlayers, updateExplorator, updatePhase, updateWord, updateRound, updateCards } } = useStore();
  const [play, setPlay] = useState(false);
  const board = useRef<HTMLDivElement>(null!);
  const coords = useRef<IPosition>({ x: 0, y: 0 });
  const currentCoords = useRef<IPosition>({ x: 0, y: 0 });
  const desiredCoords = useRef<IPosition>({ x: 0, y: 0 });
  const zoom = useRef(1);
  const timeProgress = useRef(0);
  const grabbed = useRef(false);
  const zoomWrapper = useRef<HTMLDivElement>(null!)
  const navigate = useNavigate();

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "start_explorer_phase_stella", handleStartExplorerPhase);
      window.subscribeMessage("stella", "end_round", handleEndRound);
      window.subscribeMessage("stella", "end_game", handleEndGame);
      window.subscribeMessage("stella", "game_data_stella", handleGameData);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "start_explorer_phase_stella")
        window.unSubscribeMessage("stella", "end_round");
        window.unSubscribeMessage("stella", "end_game");
        window.unSubscribeMessage("stella", "game_data_stella");
      }
    }
  }, [myPlayer])

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "connected", handleReconnect);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "connected");
      }
    }
  }, [])

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "update_player_status_stella", handleUpdatePlayerStatus);
      window.subscribeMessage("stella", "post_card_selection_stella", handlePostCardSelection);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "update_player_status_stella")
        window.unSubscribeMessage("stella", "post_card_selection_stella")
      }
    }
  }, [players])

  useGesture(() => {
    // play.current = true;
    setPlay(true);
    grabbed.current = true;
  }, (delta) => {
    let x = coords.current.x + delta.x;
    let y = coords.current.y + delta.y;
    desiredCoords.current = { x: x, y: y }
  }, (pos, e) => {
    // if (pos.x !== 0 || pos.y !== 0) {
    //     console.log("Stop prop")
    //     e.stopPropagation();
    //     e.preventDefault();
    // }
    coords.current = { x: coords.current.x + pos.x, y: coords.current.y + pos.y }
    grabbed.current = false;
  }, (delta, wheel) => {
    zoom.current += delta / 500;
    zoomWrapper.current.style.fontSize = `${zoom.current}vw`;

    wheel && setPlay(true);
    let x = currentCoords.current.x;
    let y = currentCoords.current.y;
    x += delta !== 0 ? delta > 0 ? -30 : 30 : 0;
    y += delta !== 0 ? delta > 0 ? -30 : 30 : 0;
    coords.current = { x: x, y: y }
    desiredCoords.current = { x: x, y: y }
  });

  useAnimationFrame((deltaTime) => {
    timeProgress.current += deltaTime;

    const calcX = lerp(currentCoords.current.x, desiredCoords.current.x, deltaTime / 60);
    const stopX = (Math.abs(currentCoords.current.x - desiredCoords.current.x) < 5)

    const calcY = lerp(currentCoords.current.y, desiredCoords.current.y, deltaTime / 60);
    const stopY = (Math.abs(currentCoords.current.y - desiredCoords.current.y) < 5)

    currentCoords.current.x = calcX;
    currentCoords.current.y = calcY;
    board.current.style.transform = `translateX(${calcX}px) translateY(${calcY}px)`;

    if (stopX && stopY && !grabbed.current) {
      setPlay(false);
    }
  }, play)

  useKeyDown("1", () => {
    // setMyPlayerID("P3");
    setupStella({
      round: 1,
      explorator: "P3",
      cards: [[{ image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }], [{ image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }], [{ image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }]],
      phase: Phase.selection,
      players: [
        { playerID: "P1", nickname: "William Bardon", color: "#c12626", points: 0, steps: 1 },
        { playerID: "P2", nickname: "Matteo Peroni", color: "#72c227", points: 0, steps: 1 },
        { playerID: "P3", nickname: "Dario Freschini", color: "#2791c2", points: 0, steps: 1, matrix: [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]] },
        { playerID: "P4", nickname: "Giulia ", color: "#5d27c2", points: 0, steps: 3 }
      ]
    }, "P3")
  })

  // Message received:
  // Update players status
  function handleUpdatePlayerStatus(message: IMessage) {
    let player = players.find(x => x.playerID === message.value.playerID);
    if (player) {
      player.ready = message.value.ready;
      updatePlayer(player)
    }
  }
  // Set everything for the exploration phase
  function handleStartExplorerPhase(message: IMessage) {
    updatePhase(Phase.exploration);
    updateExplorator(message.value.explorator);
    let players: IPlayer[] = message.value.players;
    if (myPlayer) {
      let myIndex = players.findIndex(x => x.playerID === myPlayer.playerID)
      if (myIndex > -1) {
        players[myIndex].matrix = myPlayer.matrix;
      }
      updatePlayers(players, myPlayer.playerID);
    }
  }
  // Update players points, 
  function handlePostCardSelection(message: IMessage) {
    updateExplorator(message.value.explorator);
    let card: IPosition = message.value.cardToRemove;
    let players: IPlayer[] = message.value.players;
    if (myPlayer) {
      myPlayer.matrix[card.x][card.y] = 0;
      let myIndex = players.findIndex(x => x.playerID === myPlayer.playerID)
      if (myIndex > -1) {
        players[myIndex].matrix = myPlayer.matrix;
      }
      updatePlayers(players, myPlayer.playerID);
    }
  }
  // Handle end of the round
  function handleEndRound(message: IMessage) {
    updateWord(message.value.word);
    updateRound(message.value.round);
    updateExplorator(message.value.explorator);
    updateCards(message.value.cards);
    updatePhase(Phase.selection);
    let players: IPlayer[] = message.value.players;
    if (myPlayer) {
      let myIndex = players.findIndex(x => x.playerID === myPlayer.playerID)
      if (myIndex > -1) {
        players[myIndex].matrix = myPlayer.matrix;
        for (let x = 0; x < 3; x++) for (let y = 0; y < 5; y++) players[myIndex].matrix[x][y] = 0;
      }
      updatePlayers(players, myPlayer.playerID);
    }
  }
  // Handle end of the game
  function handleEndGame(message: IMessage) {
    let players: IPlayer[] = message.value.players;
    if (myPlayer) {
      let myIndex = players.findIndex(x => x.playerID === myPlayer.playerID)
      if (myIndex > -1) {
        players[myIndex].matrix = myPlayer.matrix;
        for (let x = 0; x < 3; x++) for (let y = 0; y < 5; y++) players[myIndex].matrix[x][y] = 0;
      }
      updatePlayers(players, myPlayer.playerID);
    }
    navigate("/leaderboard")
  }
  // Handle refresh and temporary disconnection from the websocket
  function handleReconnect(message: IMessage) {
    // Try to reconnect the current game:
    let prevClientID = localStorage.getItem("previousClientID");
    if (prevClientID) {
      let message: IMessage = {
        lobbyCode: localStorage.getItem("lobbyCode") || "",
        type: "reconnect_stella",
        value: prevClientID
      }
      window.sendWsMessage(message);
    }
  }
  // Retrive the game data to restore the previous situation
  function handleGameData(message: IMessage) {
    let gameData = message.value.data;
    updateWord(gameData.word);
    updateRound(gameData.round);
    updateExplorator(gameData.explorator);
    updateCards(gameData.cards);
    updatePhase(gameData.phase);
    setMyPlayerID(message.value.myPlayerID);
    let players: IPlayer[] = gameData.players;
    let myIndex = players.findIndex(x => x.playerID === message.value.myPlayerID)
    if (myIndex > -1) {
      if (myPlayer) { players[myIndex].matrix = myPlayer.matrix; }
      else { players[myIndex].matrix = [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]]; }
    }
    updatePlayers(players, message.value.myPlayerID);
  }

  return (
    <>
      <div ref={zoomWrapper} className="stella-zoom">
        <div ref={board} className="stella">
          <Rounds />
          <Board />
        </div>
      </div>
      <Players />
      <Notifications />
      <Helper />
      <Actions />
      <Chat />
    </>
  )
}



export default observer(Stella);