import { MathUtils, Vector3 } from "three";
import * as THREE from "three";
import { IPosition } from "../models/map";

enum Direction {
    unset,
    right,
    left,
    up,
    down
}
let direction: Direction;
let moveTo: Vector3 | null = null;

// Resets utility variables:
export function resetMovement() {
    direction = Direction.unset;
    moveTo = null;
}

export function handleMovement(obj: THREE.Object3D, paths: IPosition[], delta: number, onEndMove: () => void) {
    // objsInTheWay = objects;
    if (paths.length > 0) {
        if (!moveTo) {
            moveTo = new Vector3(paths[0].x, 0, paths[0].z);
        }
        handleX(obj, paths);
        if (direction !== Direction.unset) {
            if (direction === Direction.left || direction === Direction.right) {
                obj.position.x = MathUtils.lerp(obj.position.x, moveTo.x + (direction === Direction.left ? -2 : 2), delta);
            } else {
                obj.position.z = MathUtils.lerp(obj.position.z, moveTo.z + (direction === Direction.up ? 2 : -2), delta);
            }
        }
        handleRotate(obj);
    } else {
        onEndMove();
    }
}

function handleX(obj: THREE.Object3D, paths: IPosition[]) {
    if (moveTo) {
        if (obj.position.x !== moveTo.x) {
            if (direction === Direction.unset) {
                if (obj.position.x < moveTo.x) {
                    direction = Direction.right;
                } else {
                    direction = Direction.left;
                }
            } else {
                if ((direction === Direction.right && obj.position.x > moveTo.x) ||
                    (direction === Direction.left && obj.position.x < moveTo.x)) {
                    direction = Direction.unset;
                    obj.position.x = moveTo.x;
                    if (obj.position.z !== moveTo.z) {
                        handleZ(obj, paths);
                    } else {
                        if (paths.length > 0) {
                            moveTo = new Vector3(paths[0].x, 0, paths[0].z);
                        } else {
                            resetMovement();
                        }
                    }
                }
            }
        } else {
            if (obj.position.z !== moveTo.z) {
                handleZ(obj, paths);
            } else {
                paths.splice(0, 1);
                if (paths.length > 0) {
                    moveTo = new Vector3(paths[0].x, 0, paths[0].z);
                } else {
                    resetMovement();
                }
            }
        }
    }
}

function handleZ(obj: THREE.Object3D, paths: IPosition[]) {
    if (moveTo) {
        if (obj.position.z !== moveTo.z) {
            if (direction === Direction.unset) {
                if (obj.position.z < moveTo.z) {
                    direction = Direction.up;
                } else {
                    direction = Direction.down;
                }
            } else {
                if ((direction === Direction.up && obj.position.z > moveTo.z) ||
                    (direction === Direction.down && obj.position.z < moveTo.z)) {
                    direction = Direction.unset;
                    obj.position.z = moveTo.z;
                    if (obj.position.x !== moveTo.x) {
                        handleX(obj, paths);
                    } else {
                        if (paths.length > 0) {
                            moveTo = new Vector3(paths[0].x, 0, paths[0].z);
                        } else {
                            resetMovement();
                        }
                    }
                }
            }
        } else {
            if (obj.position.x !== moveTo.x) {
                handleX(obj, paths);
            } else {
                if (paths.length > 0) {
                    moveTo = new Vector3(paths[0].x, 0, paths[0].z);
                } else {
                    resetMovement();
                }
            }
        }
    }
}

function handleRotate(obj: THREE.Object3D) {
    if (moveTo) {
        if (direction === Direction.left || direction === Direction.right) {
            obj.lookAt(new Vector3(moveTo.x + (direction === Direction.left ? -100 : 100), 0, obj.position.z));
        }
        if (direction === Direction.up || direction === Direction.down) {
            obj.lookAt(new Vector3(obj.position.x, 0, moveTo.z + (direction === Direction.up ? 100 : -100)));
        }
    }
}