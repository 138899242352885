import { createContext, useContext } from "react";
import CanvasStore from "../stores/canvasstore";
import ElementsClashStore from "../stores/elementsclashstore";
import LobbyStore from "../stores/lobbystore";
import MapStore from "../stores/mapstore";
import PlayerStore from "../stores/playerstore";
import { StellaStore } from "../stores/stellastore";
import WebSocketStore from "../stores/websocketstore";

export const store = {
    canvasStore: new CanvasStore(),
    mapStore: new MapStore(),
    playerStore: new PlayerStore(),

    elementsClashStore: new ElementsClashStore(),
    stellaStore: new StellaStore(),

    lobbyStore: new LobbyStore(),
    webSokcetStore: new WebSocketStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}