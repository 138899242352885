import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../hooks/usestore";
import { IMessage } from "../../hooks/usewebsocket";

const Helper = observer(() => {
  const { stellaStore: { word } } = useStore();
  const [helpPhrase, setHelpPhrase] = useState(`Select one or more cards that match the word "#word" (max 10) then press "READY"`);

  useEffect(() => {
      if (window.subscribeMessage) {
          window.subscribeMessage("stella", "help_phrase_stella", handleHelpPhrase);
      }
      return () => {
          if (window.unSubscribeMessage) {
              window.unSubscribeMessage("stella", "help_phrase_stella")
          }
      }
  }, [])

  useEffect(() => {
      setHelpPhrase(prev => prev.replace("#word", word));
  }, [word])

  function handleHelpPhrase(message: IMessage) {
      setHelpPhrase(message.value)
  }

  return (
      <div className="helper">{helpPhrase}</div>
  )
})

export default Helper;