import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef } from "react";
import { CanvasCtx } from "../canvascontext";
import CanvasElement from "../canvaselement";

interface IProps {
    layer: number
    duration: number
}

interface IConfetto {
    color: IColor,
    dimensions: IVector2,
    position: IVector2,
    rotation: number,
    scale: IVector2,
    velocity: IVector2,
}

interface IColor {
    front: string,
    back: string,
}

interface IVector2 {
    x: number,
    y: number,
}

function randomRange(min: number, max: number) { return Math.random() * (max - min) + min }

const colors = [
    { front: '#db8e1e', back: '#f8ca0a' },
    { front: '#e7a634', back: '#f2c506' },
    { front: '#cd962c', back: '#f8ca0a' },
    { front: '#f2c506', back: '#df971a' },
];

const confettiCount = 500;
const gravity = 0.5;
const terminalVelocity = 5;
const drag = 0.075;

const Confetti = ({ layer = 0, duration }: IProps) => {
    const { canvas, context, addElement } = useContext(CanvasCtx);
    const confetti = useRef<IConfetto[]>([])

    useEffect(() => {
        if (canvas && context) {
            const elementID = _.uniqueId();
            const element = new CanvasElement(elementID, layer, context, "background", handleCreate, handleUpdate, { move: true });
            addElement(element);
            initConfetti();
        }
    }, [canvas, context]);

    function initConfetti() {
        for (let i = 0; i < confettiCount; i++) {
            confetti.current.push({
                color: colors[Math.floor(randomRange(0, colors.length))],
                dimensions: {
                    x: randomRange(10, 20),
                    y: randomRange(10, 30),
                },
                position: {
                    x: randomRange(0, window.innerWidth),
                    y: window.innerHeight - 1,
                },
                rotation: randomRange(0, 2 * Math.PI),
                scale: {
                    x: 1,
                    y: 1,
                },
                velocity: {
                    x: randomRange(-25, 25),
                    y: randomRange(0, -50),
                },
            });
        }
    }

    function handleCreate() {

    }

    function handleUpdate() {
        confetti.current.forEach((confetto, index) => {
            let width = (confetto.dimensions.x * confetto.scale.x);
            let height = (confetto.dimensions.y * confetto.scale.y);

            // Move canvas to position and rotate
            context!.translate(confetto.position.x, confetto.position.y);
            context!.rotate(confetto.rotation);

            // Apply forces to velocity
            confetto.velocity.x -= confetto.velocity.x * drag;
            confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity);
            confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

            // Set position
            confetto.position.x += confetto.velocity.x;
            confetto.position.y += confetto.velocity.y;

            // Delete confetti when out of frame
            if (confetto.position.y >= window.innerHeight) confetti.current.splice(index, 1);

            // Loop confetto x position
            if (confetto.position.x > window.innerWidth) confetto.position.x = 0;
            if (confetto.position.x < 0) confetto.position.x = window.innerWidth;

            // Spin confetto by scaling y
            confetto.scale.y = Math.cos(confetto.position.y * 0.1);
            context!.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

            // Draw confetto
            context!.fillRect(-width / 2, -height / 2, width, height);

            // Reset transform matrix
            context!.setTransform(1, 0, 0, 1, 0, 0);
        });
    }

    return <></>
}

export default observer(Confetti)