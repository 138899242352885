import { observer } from "mobx-react-lite";
import { ReactNode, useCallback, useState } from "react"
import { Vector3 } from "three";
import useEventListener from "../../hooks/useeventlistener";
import { useStore } from "../../hooks/usestore";
import { IMessage } from "../../hooks/usewebsocket";
import { CharacterType } from "../../models/character";
import { IPlayerStartingPosition, IPosition, MapTile } from "../../models/map";
import Warrior from "../characters/warrior";
import Witch from "../characters/witch";
import Highlight from "../effects/highlight";
import Floor from "./floor"

export function generateMap(map: MapTile[][]) {
    const components: ReactNode[] = []
    map.forEach((x, xIndex) => {
        x.forEach((z, zIndex) => {
            components.push(<Floor tile={z} position={new Vector3(xIndex, -0.1, zIndex)} />)
        });
    })
    return components;
}

interface IInitializePlayerProps {
    startingPositions: IPlayerStartingPosition[]
}

export const InitializePlayers = observer(({ startingPositions }: IInitializePlayerProps) => {
    const { mapStore: { }, lobbyStore: { lobbyUsers } } = useStore();

    return (
        <>
            {
                lobbyUsers.filter(x => x.characters && x.characters.length > 0).map(x => {
                    let type = x.characters![0].type;
                    let startPos = startingPositions.find(y => y.playerId === x.playerId);
                    if (startPos) {
                        let pos = new Vector3(startPos.position.x, 0, startPos.position.z);
                        switch (type) {
                            case CharacterType.Warrior: return <Warrior key={x.playerId} id={`character_${x.playerId}`} position={pos} />
                            case CharacterType.Witch: return <Witch key={x.playerId} id={`character_${x.playerId}`} position={pos} />
                        }
                    }
                    return <></>
                })
            }
        </>
    )
});

enum HighlightType {
    Movement,
    Attack
}
interface IHighlight {
    type: HighlightType,
    positions: IPosition[]
}

export const MovementHelpers = observer(() => {
    const { canvasStore: { elements }, lobbyStore: { lobbyCode }, mapStore: { getPossibleMovements } } = useStore();
    // const { canvasStore: { elements }, lobbyStore: { lobbyCode, currentPlayer }, mapStore: { getPossibleMovements } } = useStore();
    const [highlights, setHighlights] = useState<IHighlight>({ type: HighlightType.Movement, positions: [] });
    const currentPlayer: any = null;
    
    const handleMove = useCallback(() => {
        // Get possible position:
        console.log("currentPlayer", currentPlayer?.clientID)
        if (currentPlayer) {
            let player = elements.find(x => x.id === `character_${currentPlayer.playerId}`);
            console.log("player", player, elements)
            if (player && player.position) {
                let cells = getPossibleMovements(player.position, player.obj.agility);
                if (cells.length > 0) {
                    setHighlights({ type: HighlightType.Movement, positions: cells });
                }
            }
        }
    }, [currentPlayer, elements])

    useEventListener("onMove", handleMove)

    const handleAttack = useCallback(() => {
        // Get possible position:
        console.log("attack", currentPlayer?.clientID)
        if (currentPlayer) {
            let player = elements.find(x => x.id === `character_${currentPlayer.playerId}`);
            console.log("player", player, elements)
            if (player && player.position) {
                let cells = getPossibleMovements(player.position, player.obj.range);
                if (cells.length > 0) {
                    setHighlights({ type: HighlightType.Attack, positions: cells });
                }
            }
        }
    }, [currentPlayer, elements])

    useEventListener("onAttack", handleAttack)

    const handleMoved = () => {
        setHighlights({ type: HighlightType.Movement, positions: [] });
    }

    useEventListener("onMoved", handleMoved)

    const highlightClickMove = useCallback((position: Vector3) => {
        if (currentPlayer) {
            const player = currentPlayer;
            const playerObj = elements.find(x => x.id === `character_${player.playerId}`)
            if (playerObj) {
                playerObj.obj.onMove(position);
            }
            handleMoved();
            // Send to other clients the new position
            let message: IMessage = {
                lobbyCode: lobbyCode,
                type: "move_character",
                value: {
                    id: `character_${player.playerId}`,
                    position: position
                }
            }
            window.sendWsMessage(message);
        }
    }, [elements, currentPlayer])

    const highlightClickAttack = useCallback((position: Vector3) => {
        if (currentPlayer) {
            const player = currentPlayer;
            const playerObj = elements.find(x => x.id === `character_${player.playerId}`)
            if (playerObj) {
                playerObj.obj.onMove(position);
            }
            handleMoved();
            // Send to other clients the new position
            let message: IMessage = {
                lobbyCode: lobbyCode,
                type: "attack_character",
                value: {
                    id: `character_${player.playerId}`,
                    position: position
                }
            }
            window.sendWsMessage(message);
        }
    }, [elements, currentPlayer])

    return (
        <>
            {
                highlights.positions.length > 0 ?
                    highlights.positions.map(pos => {
                        return <Highlight key={`${pos.x}_${pos.z}`} model={highlights.type === HighlightType.Movement ? "highlight_01" : "highlight_02"} position={new Vector3(pos.x, 0, pos.z)} action={highlights.type === HighlightType.Movement ? highlightClickMove : highlightClickAttack} />
                    })
                    :
                    null
            }
        </>
    )
});
