import { ReactNode, useCallback, useEffect, useRef } from "react";
import * as THREE from "three";
import { observer } from "mobx-react-lite";
import { useStore } from "../hooks/usestore";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { deflate } from "zlib";
import { Clock } from "three";

interface IProps {
	children?: ReactNode | ReactNode[]
}

let delta = 0;
const interval = 1 / 60;
const clock = new THREE.Clock();

const Canvas3D = ({ children }: IProps) => {
	const { canvasStore: { scene, camera, renderer, finalComposer, elements, initialize } } = useStore();
	const canvasRef = useRef<HTMLDivElement>(null!);
	const _finalComposer = useRef<EffectComposer | null>(null);
	const animationStarted = useRef(false);

	console.log("Just canvas 3d")

	useEffect(() => {
		if (initialize) {
			console.log("Initialize")
			const scene = new THREE.Scene();
			const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
			camera.position.z = 5;
			const renderer = new THREE.WebGLRenderer({ antialias: true });
			renderer.toneMapping = THREE.ReinhardToneMapping;
			renderer.setSize(window.innerWidth, window.innerHeight);
			renderer.toneMappingExposure = 0.8;
			initialize(scene, camera, renderer, canvasRef.current);
		}
	}, [initialize])

	useEffect(() => {
		if (scene && camera && (renderer || finalComposer)) {
			console.log("Start animating")
			if (!animationStarted.current) {
				console.log("Animating")
				animationStarted.current = true;
				handleAnimate();
			}
			window.addEventListener("resize", handleResize);
			return () => {
				window.removeEventListener("resize", handleResize);
			}
		}
	}, [renderer, finalComposer, scene, camera]);

	useEffect(() => {
		_finalComposer.current = finalComposer;
	}, [finalComposer])

	const handleResize = useCallback(() => {
		if ((renderer || finalComposer) && camera) {
			camera.aspect = window.innerWidth / window.innerHeight;
			camera.updateProjectionMatrix();
			if (finalComposer) {
				finalComposer.setSize(window.innerWidth, window.innerHeight)
			}
			if (renderer) renderer.setSize(window.innerWidth, window.innerHeight);
		}
	}, [renderer, finalComposer, camera])

	//const handleAnimate = useCallback(() => {
	const handleAnimate = () => {
		requestAnimationFrame(handleAnimate);
		let currentDelta = clock.getDelta();

		delta += currentDelta;
		if (delta > interval) {
			delta = delta % interval;
			if (scene && camera && (renderer || finalComposer)) {
				if (elements) {
					elements.forEach(x => { if (x.animate) x.animate(currentDelta) })
				}
				// Check if there is a final composer active:
				// - If there is one use it for render the scene.
				// - If not use the default renderer.
				// if (_finalComposer.current) {
				// 	_finalComposer.current.render();
				// } else if (renderer) {
				// 	renderer.render(scene, camera);
				// }
				if (renderer) renderer.render(scene, camera);
			}
		}
	}
	// }, [renderer, finalComposer, scene, camera])

	return (
		<>
			<div ref={canvasRef} />
			{children}
		</>
	)
}

export default observer(Canvas3D);