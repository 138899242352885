import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { invertColor } from "../../functions/utility";
import { useStore } from "../../hooks/usestore";
import { IPlayer } from "../../models/stella";
import { ReactComponent as PlayerBackground1 } from "../../assets/images/stella/player_bg_01.svg";
import { ReactComponent as Vote } from "../../assets/images/stella/vote.svg";
import explorer from "../../assets/images/stella/explorer.png";
import PlayerForeground1 from "../../assets/images/stella/player_fg_01.png";
// import "../../styles/games/stella/players.scss";

const Players = observer(() => {
  const { stellaStore: { myPlayer, players, explorator } } = useStore();

  return (
    <div className="leaderboard">
      {players.length > 0 ? players.map(x => <PlayerInfo key={x.playerID} player={x} explorator={explorator === x.playerID} myPlayer={myPlayer?.playerID === x.playerID} />) : null}
    </div>
  )
})

interface IPlayerInfo {
  player: IPlayer,
  explorator: boolean,
  myPlayer: boolean,
}

const PlayerInfo = ({ player, explorator, myPlayer }: IPlayerInfo) => {
  return (
    <div className="anchor">
      <div className="player" style={{ "--player-color": player.color, "--player-text-color": invertColor(player.color, true), opacity: myPlayer ? 1 : 0.5 } as CSSProperties}>
        {player.ready ? <Vote className="ready" /> : null}
        <span className="nickname">{player.nickname}</span><span className="points">{player.points}</span><PlayerBackground1 className="background" /><img className="foreground" src={PlayerForeground1} />
      </div>
      {explorator ? <img className="explorer" src={explorer} /> : null}
    </div>
  )
}

export default Players;