import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/usestore";

const Word = observer(() => {
  const { stellaStore: { word } } = useStore();
  return (
    <div className="word">
      {word}
    </div>
  )
})

export default Word;