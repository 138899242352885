import { useEffect } from "react";
import { useStore } from "../../hooks/usestore";
import { observer } from "mobx-react-lite";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { Vector3 } from "three";
import { MapTile } from "../../models/map";

interface IProps {
    position?: Vector3,
    tile: MapTile,
}

function getGltfFromTile(tile: MapTile) {
    switch (tile) {
        case MapTile.floor1: return "floor_01";
        case MapTile.floor2: return "floor_02";
        case MapTile.floor3: return "floor_03";
        case MapTile.floor4: return "floor_04";
    }
}

const Floor = ({ position = new Vector3(), tile }: IProps) => {
    const { canvasStore: { scene } } = useStore();
    useEffect(() => {
        if (scene) {
            const loader = new GLTFLoader();
            loader.load(`assets/models/tiles/${getGltfFromTile(tile)}.gltf`, function (gltf) {
                gltf.scene.position.set(position.x, position.y, position.z);
                scene.add(gltf.scene);
            }, undefined, function (error) {
                console.error(error);
            });
        }
    }, [scene])
    return (
        <></>
    )
}

export default observer(Floor);