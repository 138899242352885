import { useState, useEffect, useRef } from "react";
import useGesture, { IPosition } from "../../hooks/usegesture";
import { useStore } from "../../hooks/usestore";
import { IMessage } from "../../hooks/usewebsocket";
import { ICard, Phase } from "../../models/stella";
import Steps from "./steps";
import background from "../../assets/images/stella/background.jpeg";
import useAnimationFrame from "../../hooks/useanimationframe";
import { lerp } from "../../functions/common";
import { observer } from "mobx-react-lite";
import Ready from "./ready";
import "../../styles/games/stella_updated/board.scss";

const Board = () => {
  const { lobbyStore: { lobbyCode }, stellaStore: { myPlayer, explorator, players, cards, phase, updateMyMatrix, updateSingleCard } } = useStore();
  const [highlightedCard, setHighlightedCard] = useState<IPosition>();
  const [play, setPlay] = useState(false);
  const draggable = useRef<HTMLDivElement>(null!);
  const coords = useRef<IPosition>({ x: 0, y: 0 });
  const currentCoords = useRef<IPosition>({ x: 0, y: 0 });
  const desiredCoords = useRef<IPosition>({ x: 0, y: 0 });
  const zoom = useRef(0.7);
  const timeProgress = useRef(0);
  const grabbed = useRef(false);
  const zoomHandler = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("lobby", "highlight_card_selection_stella", handleHightlightCardSelection);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("lobby", "highlight_card_selection_stella");
      }
    }
  }, [])

  function handleHightlightCardSelection(message: IMessage) {
    const { cardSelected, players } = message.value;
    console.log("logs", cardSelected, players, cards);
    const card: ICard = {
      image: cards[cardSelected.x][cardSelected.y].image,
      players: players
    }
    updateSingleCard(cardSelected, card)
    setHighlightedCard(message.value)
    setTimeout(() => {
      setHighlightedCard(undefined);
    }, 3000);
  }

  function onCardClick(x: number, y: number) {
    console.log("selected card: ", x, y)
    if (phase === Phase.selection) {
      // Check selected cards:
      let selectedCards = 0;
      if (myPlayer) myPlayer.matrix.forEach((xs, indX) => xs.forEach((ys, indY) => selectedCards += x === indX && y === indY ? 0 : ys));
      if (selectedCards <= 9) {
        updateMyMatrix(x, y)
      } else {
        console.log("Maximun number of cards selected reached (10)")
      }
    } else if (myPlayer && explorator === myPlayer.playerID && myPlayer.matrix[x][y] > 0) {
      let message: IMessage = {
        lobbyCode: lobbyCode,
        type: "card_selection_stella",
        value: {
          card: {
            x: x,
            y: y
          }
        }
      }
      window.sendWsMessage(message);
    }
  }

  function getCardClasses(x: number, y: number) {
    let classes = "card";
    let selected = myPlayer && myPlayer.matrix[x][y] > 0;
    if (phase === Phase.exploration) {
      if (highlightedCard && highlightedCard.x === x && highlightedCard.y === y) { classes += " highlighted" }
      else if (!selected) classes += " greyed";
    }
    else if (selected) {
      classes += " selected";
    }
    return classes;
  }

  // useEffect(() => {
  //   zoomHandler.current.style.fontSize = `${zoom.current}vw`;
  // }, [])

  // useGesture(() => {
  //   // play.current = true;
  //   setPlay(true);
  //   grabbed.current = true;
  // }, (delta) => {
  //   let x = coords.current.x + delta.x;
  //   let y = coords.current.y + delta.y;
  //   desiredCoords.current = { x: x, y: y }
  // }, (pos, e) => {
  //   // if (pos.x !== 0 || pos.y !== 0) {
  //   //     console.log("Stop prop")
  //   //     e.stopPropagation();
  //   //     e.preventDefault();
  //   // }
  //   coords.current = { x: coords.current.x + pos.x, y: coords.current.y + pos.y }
  //   grabbed.current = false;
  // }, (delta, wheel) => {
  //   zoom.current += delta / 500;
  //   zoomHandler.current.style.fontSize = `${zoom.current}vw`;

  //   wheel && setPlay(true);
  //   let x = currentCoords.current.x;
  //   let y = currentCoords.current.y;
  //   x += delta !== 0 ? delta > 0 ? -30 : 30 : 0;
  //   y += delta !== 0 ? delta > 0 ? -30 : 30 : 0;
  //   coords.current = { x: x, y: y }
  //   desiredCoords.current = { x: x, y: y }
  // });

  // useAnimationFrame((deltaTime) => {
  //   timeProgress.current += deltaTime;

  //   const calcX = lerp(currentCoords.current.x, desiredCoords.current.x, deltaTime / 60);
  //   const stopX = (Math.abs(currentCoords.current.x - desiredCoords.current.x) < 5)

  //   const calcY = lerp(currentCoords.current.y, desiredCoords.current.y, deltaTime / 60);
  //   const stopY = (Math.abs(currentCoords.current.y - desiredCoords.current.y) < 5)

  //   currentCoords.current.x = calcX;
  //   currentCoords.current.y = calcY;
  //   draggable.current.style.transform = `translateX(${calcX}px) translateY(${calcY}px)`;

  //   if (stopX && stopY && !grabbed.current) {
  //     setPlay(false);
  //   }
  // }, play)

  return <section ref={zoomHandler} className="board">
    <div className="background">
      <img src={background} />
      <Steps />
    </div>
    <div className="anchor">
      <div className="cards-list">
        {
          cards.map((x, indX) => x.map((y, indY) => <div key={`card-${indX}-${indY}`} className={getCardClasses(indX, indY)} onClick={() => onCardClick(indX, indY)}>
            <img alt={`Card ${indX} - ${indY}`} src={y.image}></img>
            <div className="players">
              {y.players && y.players.length > 0 ? y.players?.map(z => {
                let player = players.find(p => p.playerID === z);
                return <div style={{ color: player ? player.color : "var(--white)" }}>{z}</div>
              }) : null}
            </div>
          </div>))
        }
      </div>
      <Ready />
    </div>
  </section>
}

export default observer(Board);