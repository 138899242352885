import { useEffect, useRef, useState } from "react";
// (TODO: Insert cardback in an indexdb to retrive it faster)
import { observer } from "mobx-react-lite";
import useDrag from "../hooks/usedrag";
import useMousePosition from "../hooks/usemouseposition";
import Clash from "../games/elements_clash/containers/clash";
import Info from "../games/elements_clash/containers/info";
import { useStore } from "../hooks/usestore";
import Deck from "../games/elements_clash/containers/deck";
import Field from "../games/elements_clash/containers/field";
import Hand from "../games/elements_clash/containers/hand";
import { ICard } from "../models/elementsclash";
import { Utility } from "../functions/common";
import { cards } from "../games/elements_clash/democards";
// import Timer from "../components/timer";
// import AIHandler from "../components/aiHandler";
import "../styles/games/elements_clash/game.scss";

const Game = () => {
    const { elementsClashStore: { playerHand, setPlayerHand, otherPlayerHand, setOtherPlayerHand, setDeck, setFieldCards, grabbedCard } } = useStore();
    const gameRef = useRef<HTMLDivElement>(null!);

    const mousePos = useMousePosition();

    useDrag(gameRef.current, [grabbedCard, mousePos.deltaMousePosition], {
        onPointerLeave: (e) => {
            if (grabbedCard && grabbedCard.pointerUpCallback) {
                grabbedCard.pointerUpCallback(e);
            }
        },
        onPointerUp: (e) => {
            if (grabbedCard && grabbedCard.pointerUpCallback) {
                grabbedCard.pointerUpCallback(e);
            }
        },
        onPointerMove: (e) => {
            if (grabbedCard && grabbedCard.pointerMoveCallback) {
                grabbedCard.pointerMoveCallback(e, mousePos.deltaMousePosition.x, mousePos.deltaMousePosition.y);
            }
        },
    })

    useEffect(() => {
        /* Load deck (In a multiplayer game this has to done server side to sync each player deck) */
        let _deck: ICard[] = [];
        cards.sort(x => Math.random() - 0.5).forEach(x => {
            let artIndex = Utility.randomBetweenTwoNumbers(0, x.alt.length - 1);
            _deck.push({ ...x, artwork: x.alt[artIndex] });
        });

        // Handle first draw: (TODO: first draw animation)
        setPlayerHand(_deck.splice(0, 7));
        setOtherPlayerHand(_deck.splice(0, 7));

        // Handle first cards on field
        setFieldCards(_deck.splice(0, 1), _deck.splice(0, 1));

        // Set remaining deck
        console.log("_deck", _deck)
        setDeck(_deck);
    }, [])

    return (
        <div ref={gameRef} className="game">
            {/* <Timer />
            <AIHandler /> */}
            <Clash />
            <Hand cards={otherPlayerHand} otherPlayer />
            <Info />
            <Field />
            <Deck />
            <Hand cards={playerHand} />
        </div>
    )
}

export default observer(Game);