export interface ICard {
  image: string,
  players?: string[]
}

export interface IPlayer {
  online?: boolean,
  playerID: string,
  nickname: string,
  color: string,
  points: number,
  steps: number,
  matrix: number[][],
  ready: boolean,
  fallen: boolean,
  darkness: boolean,
}

export enum Phase {
  selection,
  exploration
}

export interface IChatMessage {
  playerID: string,
  message: string,
}