import { useCallback, useEffect, useRef } from "react";
import { useStore } from "../../hooks/usestore";
import { observer } from "mobx-react-lite";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { BLOOM_SCENE } from "../postprocessing/bloompass";
import { IPlayer } from "../../models/player";
import { Group, Vector3 } from "three";
import { callEvent } from "../../hooks/useeventlistener";
import { IUser } from "../../models/user";
import { IMessage } from "../../hooks/usewebsocket";

interface IProps {
    model: string,
    position: Vector3,
    action: (position: Vector3) => void,
}

const Highlight = ({ model, position, action }: IProps) => {
    const { canvasStore: { scene, elements, addElement }, lobbyStore: { lobbyCode } } = useStore();
    const currentPlayerRef = useRef<IUser>()
    const obj = useRef<Group>()
    useEffect(() => {
        if (scene) {
            const loader = new GLTFLoader();
            loader.load(`assets/models/tiles/${model}.gltf`, function (gltf) {
                obj.current = gltf.scene;
                if (position) {
                    gltf.scene.position.x = position.x;
                    gltf.scene.position.y = position.y;
                    gltf.scene.position.z = position.z;
                }
                scene.add(gltf.scene);
                gltf.scene.children[0].layers.enable(BLOOM_SCENE);
                // gltf.scene.children[0].material.emissiveIntensity = 0.15;

                addElement({ id: gltf.scene.children[0].uuid, onClick: () => action(gltf.scene.position) })
            }, undefined, function (error) {
                console.error(error);
            });
        }
        return () => {
            if (scene && obj.current) {
                scene.remove(obj.current)
            }
        }
    }, [scene, model])
    // useEffect(() => {
    //     currentPlayerRef.current = currentPlayer;
    // }, [currentPlayer])
    // Handle movement for the current player
    const handleMovement = useCallback((position: Vector3) => {
        if (currentPlayerRef.current) {
            const player = currentPlayerRef.current;
            const playerObj = elements.find(x => x.id === `character_${player.playerId}`)
            if (playerObj) {
                playerObj.obj.onMove(position);
            }
            callEvent("onMoved");
            // Send to other clients the new position
            let message: IMessage = {
                lobbyCode: lobbyCode,
                type: "move_character",
                value: {
                    id: `character_${player.playerId}`,
                    position: position
                }
            }
            window.sendWsMessage(message);
        }
        //}, [elements, currentPlayer])
    }, [elements])
    return (
        <></>
    )
}

export default observer(Highlight);