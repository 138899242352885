import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { useStore } from "../../hooks/usestore";
import playerCoin from "../../assets/images/stella/player_coin.png";
import "../../styles/games/stella_updated/steps.scss";

const Steps = observer(() => {
  return (
    <div className="steps">
      {Array.from(new Array(10).keys()).map((x, index) => <Step key={index} step={index + 1} />)}
    </div>
  )
})

interface IStepProp {
  step: number
}

const Step = observer(({ step }: IStepProp) => {
  const { stellaStore: { players } } = useStore();
  return (
    <div className="step" data-step={step}>{players.filter(x => x.steps === step && !x.fallen).map((x, index) => <div key={x.playerID} className="player-coin" style={{ "--player-color": x.color, top: `-${index * 0.5}rem` } as CSSProperties}><img src={playerCoin} /></div>)}</div>
  )
})

export default Steps;