import { action, makeObservable, observable } from "mobx";
import * as THREE from "three";
// @ts-ignore
// import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { CanvasElement3D } from "../models/canvaselement3d";
import { IPass } from "../models/common";

export default class CanvasStore {
    scene: THREE.Scene | null = null;
    camera: THREE.PerspectiveCamera | null = null;
    renderer: THREE.WebGLRenderer | null = null;
    renderPass: RenderPass | null = null;
    finalComposer: EffectComposer | null = null;
    elements: CanvasElement3D[] = [];
    passes: IPass[] = [];
    constructor() {
        makeObservable(this, {
            scene: observable,
            camera: observable,
            renderer: observable,
            renderPass: observable,
            finalComposer: observable,
            initialize: action,
            setRenderPass: action,
            setFinalComposer: action,
            addElement: action,
        });
    }

    initialize = (scene: THREE.Scene,
        camera: THREE.PerspectiveCamera,
        renderer: THREE.WebGLRenderer,
        anchorElement: HTMLElement) => {
        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;
        anchorElement.appendChild(this.renderer.domElement);
    }

    setRenderPass = (renderPass: RenderPass) => {
        this.renderPass = renderPass;
    }

    setFinalComposer = (finalComposer: EffectComposer) => {
        this.finalComposer = finalComposer;
    }

    addPass = (pass: IPass) => {
        if (this.finalComposer) {
            this.finalComposer.insertPass(pass.pass, pass.priority);
        }
    }

    addElement = (element: CanvasElement3D) => {
        this.elements.push(element);
    }
}