import { useEffect } from "react";
import { useStore } from "../../hooks/usestore";
import { observer } from "mobx-react-lite";
import * as THREE from "three";
import { Vector3 } from "three";

interface IProps {
    color: string,
    intensity: number,
}

const AmbientLight = ({ color, intensity }: IProps) => {
    const { canvasStore: { scene } } = useStore();
    useEffect(() => {
        if (scene) {
            const light = new THREE.AmbientLight(color, intensity);
            scene.add(light);
        }
    }, [scene])
    return (
        <></>
    )
}

export default observer(AmbientLight);