import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import useDrag from "../../../hooks/usedrag";
import { useStore } from "../../../hooks/usestore";
import { Origin } from "../../../models/draggable";
import Card from "../components/card";
import "../../../styles/games/elements_clash/field.scss";

interface IProps {
    // field: IField
    // otherPlayer?: boolean
}

const Field = ({ }: IProps) => {
    const { elementsClashStore: { fieldCards, setFieldCards, playerHand, setPlayerHand, grabbedCard, setGrabbedCard, roundClosed } } = useStore();
    const fieldRef = useRef<HTMLDivElement>(null!);

    useDrag(fieldRef.current, [], {
        onPointerUp: checkField,
    })

    function checkField(e: PointerEvent) {
        if (grabbedCard) {
            let hoveredCard = (e.target as HTMLElement).getAttribute("custom-name");
            if (grabbedCard.origin === Origin.hand && fieldCards.playerField.length < 5) {
                if (hoveredCard) {
                    let index = fieldCards.playerField.findIndex(x => x.name === hoveredCard);
                    let newFieldCards = fieldCards.playerField.filter(x => x.name !== grabbedCard.object.name);
                    setFieldCards([...newFieldCards.slice(0, index), grabbedCard.object, ...newFieldCards.slice(index)], fieldCards.otherPlayerField);
                } else {
                    setFieldCards([...fieldCards.playerField, grabbedCard.object], fieldCards.otherPlayerField);
                }
                setPlayerHand(playerHand.filter(x => x.name !== grabbedCard.object.name));
                setGrabbedCard(null);
            } else if (grabbedCard.origin === Origin.field) {
                if (hoveredCard) {
                    let index = playerHand.findIndex(x => x.name === hoveredCard);
                    let newFieldCards = fieldCards.playerField.filter(x => x.name !== grabbedCard.object.name);
                    setFieldCards([...newFieldCards.slice(0, index), grabbedCard.object, ...newFieldCards.slice(index)], fieldCards.otherPlayerField);
                }
            }
        }
    }

    return (
        <div className="field">
            <div className="other-player cards">
                {
                    fieldCards.otherPlayerField.length > 0 && fieldCards.otherPlayerField.map((x, index) => {
                        return <Card key={x.name} card={x} origin={Origin.field} flipped={index > 0 && !roundClosed} />
                    })
                }
            </div>
            <div ref={fieldRef} className="cards">
                {
                    fieldCards.playerField.length > 0 && fieldCards.playerField.map(x => {
                        return <Card key={x.name} card={x} draggable origin={Origin.field} />
                    })
                }
            </div>
        </div>
    )
}

export default observer(Field);