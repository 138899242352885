import { IMessage } from "../hooks/usewebsocket";

declare global {
    interface Window {
        sendWsMessage: (message: IMessage) => void,
        subscribeMessage: (id: string, type: string, callbackFn: (message: IMessage) => void) => void,
        unSubscribeMessage: (id: string, type: string) => void
    }
}

export { }

// Maths functions:

export function lerp(start: number, end: number, speed: number) {
    return (1 - speed) * start + speed * end
}

export function easeIn(time: number) {
    return time * time;
}

export function easeOut(time: number) {
    return flip(Math.sqrt(flip(time)));
}

export function easeInOut(x: number) {
    // return .5 * (Math.sin((time - .5) * Math.PI) + 1);
    return Math.sin((x * Math.PI) / 2);
}

function flip(value: number) {
    return 1 - value;
}

export class Utility {
  static randomBetweenTwoNumbers(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  static calcCardHandRotation(cardIndex: number, totalCards: number) {
    const range = 10 * totalCards;
    const offset = 330 + (7 - totalCards) * 5;
    return Math.floor((range / totalCards) * cardIndex + offset);
  }
}