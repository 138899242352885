import { useEffect, useRef } from "react";

const fps = 1 / 25;
// Reach animation frame call the callback function passed as an argument and execute it:
const useAnimationFrame = (callback: (deltaTime: number) => void, play: boolean = true) => {
    const requestRef = useRef<number>();
    const previousTimeRef = useRef<number>();
    const timeElapsed = useRef(0);

    const animate = (time: number) => {
        if (previousTimeRef.current != undefined) {
            const deltaTime = time - previousTimeRef.current;
            timeElapsed.current += deltaTime;
            if (timeElapsed.current > fps) {
                callback(timeElapsed.current);
                timeElapsed.current = timeElapsed.current % fps;
            }
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        if (play) requestRef.current = requestAnimationFrame(animate);
        else if (requestRef.current) { cancelAnimationFrame(requestRef.current); previousTimeRef.current = undefined; timeElapsed.current = 0 }
        return () => {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [callback, play]);
};

export default useAnimationFrame;