import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useStore } from "../../hooks/usestore";
import { IMessage } from "../../hooks/usewebsocket";
import "../../styles/games/stella_updated/word.scss";

const Word = observer(() => {
  const { stellaStore: { word } } = useStore();
  const [helpPhrase, setHelpPhrase] = useState(`Select one or more cards that match the word "#word" (max 10) then press "READY"`);

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "help_phrase_stella", handleHelpPhrase);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "help_phrase_stella")
      }
    }
  }, [word])

  useEffect(() => {
    setHelpPhrase(prev => prev.replace("#word", word));
  }, [word])

  function handleHelpPhrase(message: IMessage) {
    setHelpPhrase(message.value.replace("#word", word))
  }

  return (
    <section className="word">
      <h2>{word}</h2>
      <span>{helpPhrase}</span>
    </section>
  )
})

export default Word;