import { ICard } from "./elementsclash";

export interface IDraggable {
    object: ICard,
    origin: Origin,
    pointerUpCallback?: (e: PointerEvent) => void,
    pointerMoveCallback?: (e: PointerEvent, deltaX: number, deltaY: number) => void,
}

export enum Origin {
    hand,
    field,
    deck
}