import { useEffect, useRef, useState } from "react";
import useTimeout from "../../../hooks/usetimeout";
import { ReactComponent as PaperMask } from "../../../assets/mask/elementsclash/paper.svg"
import { ReactComponent as PaperAlternativeMask } from "../../../assets/mask/elementsclash/paper-alternative.svg"
import ClashCard from "../components/clashcard";
import { observer } from "mobx-react-lite";
import { ICard } from "../../../models/elementsclash";
import { useStore } from "../../../hooks/usestore";
import "../../../styles/games/elements_clash/clash.scss";

interface IClashCard extends ICard {
    clash: boolean,
    ripped: boolean,
}

enum ClashState {
    cardFight,
    postFight,
    endClash
}

const Clash = () => {
    const { elementsClashStore: { fieldCards, setFieldCards, roundClosed } } = useStore();

    const [otherPlayerCards, setOtherPlayerCards] = useState<IClashCard[]>([]);
    const [otherPlayerClashCard, setOtherPlayerClashCard] = useState<IClashCard | null>(null)

    const [playerCards, setPlayerCards] = useState<IClashCard[]>([]);
    const [playerClashCard, setPlayerClashCard] = useState<IClashCard | null>(null)

    const [timeout, setTimeout] = useState<number | null>(null);
    const clashStateRef = useRef<ClashState>(ClashState.cardFight);

    useEffect(() => {
        // Listen when round became closed and prepare for the clash between the two player:
        if (roundClosed) {
            let playerCards = fieldCards.playerField.map(x => { return { ...x, clash: false, ripped: false } });
            let otherPlayerCards = fieldCards.otherPlayerField.map(x => { return { ...x, clash: false, ripped: false } });
            prepareClash(playerCards, otherPlayerCards);
            // Wait 1 seconds to let the state to be updated:
            setTimeout(1000);
        }
    }, [roundClosed])

    function prepareClash(playerCards: IClashCard[], otherPlayerCards: IClashCard[]) {
        // Set the starting clash card for both player:
        setPlayerClashCard(playerCards[0]);
        setPlayerCards(playerCards.slice(1));
        setOtherPlayerClashCard(otherPlayerCards[0]);
        setOtherPlayerCards(otherPlayerCards.slice(1));
    }

    useTimeout(() => {
        console.log("HANDLE CURRENT STATE", clashStateRef.current)
        clash();
    }, timeout);

    function clash() {
        // Handle clash by checking in which state we currently are:
        if (otherPlayerClashCard && playerClashCard) {
            if (clashStateRef.current === ClashState.cardFight) {
                playerClashCard.clash = true;
                playerClashCard.ripped = playerClashCard.power! <= otherPlayerClashCard.power!;
                otherPlayerClashCard.clash = true;
                otherPlayerClashCard.ripped = otherPlayerClashCard.power! <= playerClashCard.power!;

                clashStateRef.current = ClashState.postFight;
                // Wait for animation to finish
                setTimeout(2500);
            }
            else if (clashStateRef.current === ClashState.postFight) {
                if (otherPlayerClashCard.ripped) {
                    if (otherPlayerCards.length > 0) {
                        setOtherPlayerClashCard(otherPlayerCards[0]);
                        setOtherPlayerCards(otherPlayerCards.slice(1));
                    } else {
                        setOtherPlayerClashCard(null);
                        clashStateRef.current = ClashState.endClash;
                    }
                } else {
                    let updatedStats = { ...otherPlayerClashCard };
                    updatedStats.power! -= playerClashCard.power!;
                    setOtherPlayerClashCard(updatedStats);
                }

                if (playerClashCard.ripped) {
                    if (playerCards.length > 0) {
                        setPlayerClashCard(playerCards[0]);
                        setPlayerCards(playerCards.slice(1));
                    } else {
                        setPlayerClashCard(null);
                        clashStateRef.current = ClashState.endClash;
                    }
                } else {
                    let updatedStats = { ...playerClashCard };
                    updatedStats.power! -= otherPlayerClashCard.power!;
                    setPlayerClashCard(updatedStats);
                }

                if (clashStateRef.current === ClashState.postFight) {
                    clashStateRef.current = ClashState.cardFight;
                }
                // Wait 1 seconds to let the state to be updated:
                setTimeout(1000);
            }
        }
        if (clashStateRef.current === ClashState.endClash) {
            console.log("END")
            setTimeout(null);
        }
    }

    return (
        <div className="clash">
            {
                roundClosed &&
                <>
                    <div className="other-player cards">
                        {
                            otherPlayerClashCard && <ClashCard key={otherPlayerClashCard.name} card={otherPlayerClashCard} clash={otherPlayerClashCard.clash} ripped={otherPlayerClashCard.ripped} />
                        }
                        {
                            otherPlayerCards.length > 0 && otherPlayerCards.map((x, index) => {
                                return <ClashCard key={x.name} card={x} clash={x.clash} ripped={x.ripped} />
                            })
                        }
                    </div>
                    <div className="cards">
                        {
                            playerClashCard && <ClashCard key={playerClashCard.name} card={playerClashCard} clash={playerClashCard.clash} ripped={playerClashCard.ripped} />
                        }
                        {
                            playerCards.length > 0 && playerCards.map((x, index) => {
                                return <ClashCard key={x.name} card={x} clash={x.clash} ripped={x.ripped} />
                            })
                        }
                    </div>
                    <div className="masks">
                        <PaperMask />
                        <PaperAlternativeMask />
                    </div>
                </>
            }
        </div>
    )
}

export default observer(Clash);