import { action, makeObservable, observable } from "mobx";
import { IDraggable } from "../models/draggable";
import { IRound, IField, ICard, roundDuration } from "../models/elementsclash";

export default class ElementsClashStore {
  //unauthorize: boolean;
  playerHand: ICard[] = [];
  otherPlayerHand: ICard[] = [];
  fieldCards: IField = { playerField: [], otherPlayerField: [] };
  deck: ICard[] = [];
  grabbedCard: IDraggable | null = null;
  remainingTime: number = roundDuration;
  currentRound: IRound = { title: "Round 1" };
  roundClosed: boolean = false;

  constructor() {
    makeObservable(this, {
      playerHand: observable,
      otherPlayerHand: observable,
      fieldCards: observable,
      deck: observable,
      grabbedCard: observable,
      remainingTime: observable,
      currentRound: observable,
      roundClosed: observable,
      setPlayerHand: action,
      setOtherPlayerHand: action,
      setFieldCards: action,
      setOtherPlayerFieldCards: action,
      setDeck: action,
      setGrabbedCard: action,
      setRemainingTime: action,
      setCurrentRound: action,
    });
  }

  setPlayerHand = (cards: ICard[]) => {
    this.playerHand = cards;
  };

  setOtherPlayerHand = (cards: ICard[]) => {
    this.otherPlayerHand = cards;
  };

  setFieldCards = (playerCards: ICard[], otherPlayerCards: ICard[]) => {
    this.fieldCards = {
      playerField: playerCards,
      otherPlayerField: otherPlayerCards,
    };
  };

  setOtherPlayerFieldCards = (otherPlayerCards: ICard[]) => {
    this.fieldCards = {
      playerField: this.fieldCards.playerField,
      otherPlayerField: otherPlayerCards,
    };
  }

  setDeck = (cards: ICard[]) => {
    this.deck = cards;
  };

  setGrabbedCard = (grabbedCard: IDraggable | null) => {
    this.grabbedCard = grabbedCard;
  };

  setRemainingTime = (remainingTime: number) => {
    this.remainingTime = remainingTime;
    this.roundClosed = this.remainingTime === 0;
  }

  setCurrentRound = (currentRound: IRound) => {
    this.currentRound = currentRound;
  }
}
