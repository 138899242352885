import { useEffect } from 'react';
import Game from './pages/game';
import WebsocketManager from './components/websocketmanager';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './pages/login';
import Lobby from './pages/lobby';
import Stella from './pages/stella';
import StellaUpd from './pages/stella_updated';
import Leaderboard from './pages/stellaleaderboard';
import './App.scss';
import './styles/commons.scss';
import useWindowSize from './hooks/usewindowsize';
import ElementsClash from './pages/elementsclash';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Lobby />,
    // loader: rootLoader,
  },
  {
    path: "lobby",
    element: <Lobby />,
    // loader: teamLoader,
  },
  {
    path: "game",
    element: <Game />,
    // loader: teamLoader,
  },
  {
    path: "stella",
    element: <Stella />,
    // loader: teamLoader,
  },
  {
    path: "stella-updated",
    element: <StellaUpd />,
    // loader: teamLoader,
  },
  {
    path: "elements-clash",
    element: <ElementsClash />,
    // loader: teamLoader,
  },
  {
    path: "leaderboard",
    element: <Leaderboard />,
    // loader: teamLoader,
  },
], { basename: process.env.REACT_APP_BASENAME });

function App() {

  const size = useWindowSize();

  useEffect(() => {
    console.log("size", size)
    document.documentElement.style.setProperty("--client-height", `${window.innerHeight}px`);
  }, [size])

  return (
    <div className="App">
      <RouterProvider router={router} />
      {/* <Game /> */}
      <WebsocketManager />
    </div>
  );
}

export default App;
