import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { IPosition } from "../../hooks/usegesture";
import { useStore } from "../../hooks/usestore";
import { IMessage } from "../../hooks/usewebsocket";
import { ICard, Phase } from "../../models/stella";

const Board = observer(() => {
  const { lobbyStore: { lobbyCode }, stellaStore: { myPlayer, explorator, players, cards, phase, updateMyMatrix, updateSingleCard } } = useStore();
  const [highlightedCard, setHighlightedCard] = useState<IPosition>();

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("lobby", "highlight_card_selection_stella", handleHightlightCardSelection);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("lobby", "highlight_card_selection_stella");
      }
    }
  }, [])

  function handleHightlightCardSelection(message: IMessage) {
    const { cardSelected, players } = message.value;
    console.log("logs", cardSelected, players, cards);
    const card: ICard = {
      image: cards[cardSelected.x][cardSelected.y].image,
      players: players
    }
    updateSingleCard(cardSelected, card)
    setHighlightedCard(message.value)
    setTimeout(() => {
      setHighlightedCard(undefined);
    }, 3000);
  }

  function onCardClick(x: number, y: number) {
    if (phase === Phase.selection) {
      // Check selected cards:
      let selectedCards = 0;
      if (myPlayer) myPlayer.matrix.forEach((xs, indX) => xs.forEach((ys, indY) => selectedCards += x === indX && y === indY ? 0 : ys));
      if (selectedCards <= 9) {
        updateMyMatrix(x, y)
      } else {
        console.log("Maximun number of cards selected reached (10)")
      }
    } else if (myPlayer && explorator === myPlayer.playerID && myPlayer.matrix[x][y] > 0) {
      let message: IMessage = {
        lobbyCode: lobbyCode,
        type: "card_selection_stella",
        value: {
          card: {
            x: x,
            y: y
          }
        }
      }
      window.sendWsMessage(message);
    }
  }

  function getCardClasses(x: number, y: number) {
    let classes = "card";
    let selected = myPlayer && myPlayer.matrix[x][y] > 0;
    if (phase === Phase.exploration) {
      if (highlightedCard && highlightedCard.x === x && highlightedCard.y === y) { classes += " highlighted" }
      else if (!selected) classes += " greyed";
    }
    else if (selected) {
      classes += " selected";
    }
    return classes;
  }

  return (
    <div className="board">
      <div className="area">
        {
          cards.map((x, indX) => x.map((y, indY) => <div key={`card-${indX}-${indY}`} className={getCardClasses(indX, indY)} onClick={() => onCardClick(indX, indY)}>
            <img alt={`Card ${indX} - ${indY}`} src={y.image}></img>
            <div className="players">
              {y.players && y.players.length > 0 ? y.players?.map(z => {
                let player = players.find(p => p.playerID === z);
                return <div style={{ color: player ? player.color : "var(--white)" }}>{z}</div>
              }) : null}
            </div>
          </div>))
        }
      </div>
    </div>
  )
})

export default Board;