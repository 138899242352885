import { Vector3 } from "three";
import Witch from "../components/characters/witch";
import Highlight from "../components/effects/highlight";
import AmbientLight from "../components/lights/ambientlight";
import Pointlight from "../components/lights/pointlight";
import Finalcomposer from "../components/postprocessing/finalcomposer";
import Renderpass from "../components/postprocessing/renderpass";
import BloomPass from '../components/postprocessing/bloompass';
import Room from "../components/rooms/room";
import Stats from "../components/stats";
import Interface from "../containers/interface";
import Canvas3D from '../containers/canvas3d';
import GameCanvas from "../containers/canvas";
import Camera from '../components/camera';
import Raycaster from '../components/interactions/raycaster';
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useStore } from "../hooks/usestore";
import { demo_map, demo_map_tiles, demo_starting_pos } from "../maps/demo";
import { generateMap, InitializePlayers, MovementHelpers } from "../components/tiles/map";
import useEventListener from "../hooks/useeventlistener";
import GameHelper from "../components/gamehelper";

const Game = () => {
    const { mapStore: { createMap } } = useStore();
    const [loadCanvas, setLoadCanvas] = useState(true);
    // const [tiles, setTiles] = useState<ReactNode[]>();
    const timer = useRef<NodeJS.Timeout>();

    useLayoutEffect(() => {
        createMap(demo_map);
        // if (timer.current) {
        //     clearTimeout(timer.current);
        // }
        // timer.current = setTimeout(() => {
        //     setLoadCanvas(true);
        // }, 5000);
        // setCurrentPlayer("P1");
    }, [createMap])

    return (
        // <>
        //     <GameCanvas />
        //     <Interface />
        // </>
        <>
            {
                loadCanvas ? <Canvas3D>
                    <GameHelper />
                    <Camera />
                    {
                        generateMap(demo_map_tiles)
                    }
                    <InitializePlayers startingPositions={demo_starting_pos} />
                    <MovementHelpers />
                    {/* <Room position={new Vector3(1, 0, 2)} /> */}
                    {/* <Highlight position={new Vector3(0, 0, 0)} />
        <Highlight position={new Vector3(1, 0, 0)} />
        <Highlight position={new Vector3(2, 0, 0)} />
        <Highlight position={new Vector3(2, 0, 1)} />
        <Highlight position={new Vector3(1, 0, 1)} />
        <Highlight position={new Vector3(1, 0, 2)} />
        <Highlight position={new Vector3(2, 0, 2)} />
        <Highlight position={new Vector3(0, 0, 1)} />
        <Highlight position={new Vector3(0, 0, 2)} />

        <Highlight position={new Vector3(0, 0, 3)} />
        <Highlight position={new Vector3(1, 0, 3)} />
        <Highlight position={new Vector3(2, 0, 3)} />
        <Highlight position={new Vector3(3, 0, 0)} />
        <Highlight position={new Vector3(3, 0, 1)} />
        <Highlight position={new Vector3(3, 0, 2)} />
        <Highlight position={new Vector3(3, 0, 3)} /> */}
                    <AmbientLight color={"#c2b192"} intensity={0.25} />
                    <Pointlight position={new Vector3(2, 1, 2)} color='#fffb90' intensity={1.25} distance={10} />
                    {/* <Finalcomposer />
        <Renderpass /> */}
                    {/* <PixelatedPass priority={1} /> */}
                    {/* <BloomPass priority={2} /> */}
                    {/* <Witch position={new Vector3(1, 0, 1)} /> */}
                    <Raycaster />
                    <Stats />
                </Canvas3D>
                    :
                    null
            }
            <Interface />
        </>


    )
}

export default Game;