import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { invertColor } from "../../functions/utility";
import { useStore } from "../../hooks/usestore";
import { IPlayer } from "../../models/stella";
import "../../styles/games/stella_updated/leaderboard.scss";
import { ReactComponent as Vote } from "../../assets/images/stella/vote.svg";
import explorer from "../../assets/images/stella/explorer.png";

const Leaderboard = () => {
  const { stellaStore: { myPlayer, players, explorator } } = useStore();

  return <section className="leaderboard">
    <div className="players">
      {players.length > 0 ? players.map(x => <Player key={x.playerID} player={x} explorator={explorator === x.playerID} myPlayer={myPlayer?.playerID === x.playerID} />) : null}
    </div>
  </section>
}

export default observer(Leaderboard);

interface IPlayerInfo {
  player: IPlayer,
  explorator: boolean,
  myPlayer: boolean,
}

const Player = ({ player, explorator, myPlayer }: IPlayerInfo) => {
  return (
    <div className="player" style={{ "--player-color": player.color, "--player-text-color": invertColor(player.color, true), opacity: myPlayer ? 1 : 0.5 } as CSSProperties}>
      {player.ready ? <Vote className="icon" /> : <span />}
      <span className="nickname">{player.nickname}</span><span className="points">{player.points}</span>
      {explorator ? <img className="icon" src={explorer} /> : <span />}
    </div>
  )
}