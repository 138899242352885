import { useEffect, useRef, useCallback } from "react";
import { useStore } from "../../hooks/usestore";
import { observer } from "mobx-react-lite";
import * as THREE from "three";
import { MathUtils } from "three";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { Vector3 } from "three";
import { handleMovement, resetMovement } from "../../functions/movements";
import { IPosition } from "../../models/map";

const clock = new THREE.Clock();

interface IAnimation {
    name: string,
    clip: THREE.AnimationClip,
}

interface IProps {
    id: string,
    position?: Vector3,
}

const Warrior = ({ id, position = new Vector3() }: IProps) => {
    const { canvasStore: { scene, elements, addElement }, mapStore: { updateObjectPos, findPath } } = useStore();
    const _gltf = useRef<GLTF>();
    const animations = useRef<IAnimation[]>([]);
    const mixer = useRef<THREE.AnimationMixer>();
    const moving = useRef(false);
    const newPosition = useRef<Vector3>();
    const path = useRef<IPosition[]>([]);

    useEffect(() => {
        if (scene && addElement) {
            const loader = new GLTFLoader();
            loader.load('assets/models/warrior.gltf', function (gltf) {
                _gltf.current = gltf;

                gltf.scene.position.set(position.x, position.y, position.z);
                gltf.scene.scale.x = 0.5;
                gltf.scene.scale.y = 0.5;
                gltf.scene.scale.z = 0.5;
                scene.add(gltf.scene);

                mixer.current = new THREE.AnimationMixer(gltf.scene);
                gltf.animations.forEach((clip) => {
                    animations.current.push({ name: clip.name, clip: clip });
                    if (clip.name === "Idle") {
                        mixer.current && mixer.current.clipAction(clip).play();
                    }
                });

                addElement({
                    id: id,
                    obj: {
                        agility: 3,
                        range: 1,
                        onMove: (position: Vector3) => handleMove(position)
                    },
                    position: { x: position.x, z: position.z },
                    animate: (delta: number) => onAnimate(delta)
                })
            }, undefined, function (error) {
                console.error(error);
            });
        }
    }, [scene, addElement])

    const onAnimate = useCallback((delta: number) => {
        if (mixer.current) {
            mixer.current.update(delta * 2);
            if (moving.current) {
                let me = _gltf.current?.scene;
                if (me) {
                    handleMovement(me, path.current, delta, () => {
                        moving.current = false;
                        playAnimation("Idle")
                    });
                }
            }
        }
    }, [mixer.current])

    const handleMove = (position: Vector3) => {
        playAnimation("Walk")

        let me = _gltf.current?.scene;
        if (me) {
            let _path = findPath(me.position, position);
            // Update current player position in map store
            updateObjectPos(me.position, position);
            let myElem = elements.find(x => x.id === id);
            if (myElem) {
                myElem.position = { x: position.x, z: position.z };
            }
            if (_path.length > 0) {
                path.current = _path.slice(1);
            }
        }

        resetMovement();
        newPosition.current = position;
        moving.current = true;
    }

    const playAnimation = (name: string) => {
        if (mixer.current) {
            let anim = animations.current.find(x => x.name === name);
            if (anim) {
                mixer.current.stopAllAction();
                mixer.current.clipAction(anim.clip).play();
            }
        }
    }

    return (
        <></>
    )
}

export default observer(Warrior);