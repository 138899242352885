export enum MovementTile {
    empty,
    static_object,
    character
}

export enum MapTile {
    floor1,
    floor2,
    floor3,
    floor4,
    wall1,
    wall2,
    wall3,
    wall4,
}

export interface IPlayerStartingPosition {
    playerId: string,
    position: IPosition
}

export interface IPosition {
    x: number,
    z: number
}

export interface INode {
    parent: INode | null,
    value: number,
    x: number,
    z: number,
    f: number,
    g: number,
}