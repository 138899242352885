import { observer } from "mobx-react-lite";
import { CSSProperties, useEffect, useState } from "react";
import Canvas from "../framework/canvas2d/canvas";
import CanvasContext from "../framework/canvas2d/canvascontext";
import Confetti from "../framework/canvas2d/elements/confetti";
import { invertColor } from "../functions/utility";
import { useStore } from "../hooks/usestore";
import { IPlayer, Phase } from "../models/stella";
// @ts-ignore
import { ReactComponent as PlayerBackground1 } from "../assets/images/stella/player_bg_01.svg";
// @ts-ignore
import PlayerForeground1 from "../assets/images/stella/player_fg_01.png";
// import "../styles/pages/stellaleaderboard.scss";
import { useKeyDown } from "../hooks/usekeydown";

const Leaderboard = () => {
    const { stellaStore: { players, setupStella } } = useStore();
    const [winner, setWinner] = useState<IPlayer>()

    useEffect(() => {
        let winner = players[0];
        players.slice(1).forEach(x => {
            if (x.points > winner.points) winner = x;
        })
        setWinner(winner);
    }, [players])

    useKeyDown("1", () => {
        setupStella({
            round: 1,
            explorator: "P3",
            cards: [],
            phase: Phase.selection,
            players: [
                { playerID: "P1", nickname: "William Bardon", color: "#c12626", points: 3, steps: 1 },
                { playerID: "P2", nickname: "Matteo Peroni", color: "#72c227", points: 5, steps: 1 },
                { playerID: "P3", nickname: "Dario Freschini", color: "#2791c2", points: 15, steps: 1, matrix: [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]] },
                { playerID: "P4", nickname: "Giulia ", color: "#5d27c2", points: 7, steps: 3 }
            ]
        }, "P3")
    })

    return (
        <div className="stella-leaderboard">
            <div className="leaderboard">
                <h2>Winner is: </h2>
                {winner ? <Player player={winner} className="winner" /> : null}
                <h2>Other players: </h2>
                <div className="players">
                    {players.filter(x => x.playerID !== winner?.playerID).map(x => <Player key={x.playerID} player={x} />)}
                </div>
            </div>
            <CanvasContext>
                <Canvas play={true}>
                    <Confetti layer={0} duration={0} />
                </Canvas>
            </CanvasContext>
        </div>
    )
}

interface IProps {
    player: IPlayer,
    className?: string,
}
const Player = ({ player, className }: IProps) => {
    return (
        <div className={`player ${className}`} style={{ "--player-color": player.color, "--player-text-color": invertColor(player.color, true) } as CSSProperties}>
            <span className="nickname">{player.nickname}</span>
            <span className="points">{player.points}</span>
            <PlayerBackground1 className="background" />
            <img className="foreground" src={PlayerForeground1} />
        </div>
    )
}

export default observer(Leaderboard);