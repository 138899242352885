import { observer } from "mobx-react-lite";
import { ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import useAnimationFrame from "../../hooks/useanimationframe";
import useWindowSize from "../../hooks/usewindowsize";
// import { useStore } from "../stores/store";
import { CanvasCtx } from "./canvascontext";

const fps = 1 / 25;
interface IProps {
    play: boolean,
    children?: ReactNode | ReactNode[]
}
const CanvasFramework = ({ play, children }: IProps) => {
    const { canvas, context, elements, setupCanvas, clearCanvas } = useContext(CanvasCtx);
    const [pause, setPause] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null!);
    const size = useWindowSize();
    const _play = useRef(false);
    const delta = useRef(0);

    useEffect(() => {
        if (canvasRef.current) {
            console.log("Setup canvas")
            setupCanvas(canvasRef.current);
            return () => {
                clearCanvas();
            }
        }
    }, [])

    useEffect(() => {
        if (canvas) {
            let parent = canvasRef.current.parentElement;
            if (parent) {
                canvas.width = parent.getBoundingClientRect().width;
                canvas.height = parent.getBoundingClientRect().height;
            }
        }
    }, [canvas, canvasRef.current, size])

    useEffect(() => {
        _play.current = play;
    }, [play])

    const render = useCallback((deltaTime: number) => {
        if (_play.current) {
            if (context && canvas) {
                context.clearRect(0, 0, canvas.width, canvas.height)
            }
            elements.forEach(x => {
                x.onUpdate(deltaTime);
            })
        }
    }, [context, canvas, elements])

    useEffect(() => {
        let pause = true;
        elements.forEach(x => { pause = x.additionalData.move ? false : true; if (!pause) return; });
        if (!pause) {
            setPause(false);
        } else {
            setPause(true);
        }
    }, [context, canvas, elements])

    useAnimationFrame(render, pause);

    return (
        <>
            <canvas ref={canvasRef} />
            {children}
        </>
    )
}

export default observer(CanvasFramework)