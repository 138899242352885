
export const cards = [
  {
    "name": "death",
    "type": 1,
    "power": 15,
    "elementType": 1,
    "alt": [
      "example1.png",
      "example2.png"
    ]
  },
  {
    "name": "justice",
    "type": 1,
    "power": 17,
    "elementType": 1,
    "alt": [
      "example3.png"
    ]
  },
  {
    "name": "planet eater",
    "type": 1,
    "power": 20,
    "elementType": 1,
    "alt": [
      "example4.png"
    ]
  },
  {
    "name": "giant wurm octopus",
    "type": 1,
    "power": 15,
    "elementType": 1,
    "alt": [
      "example5.jpg"
    ]
  },
  {
    "name": "terror spider",
    "type": 1,
    "power": 20,
    "elementType": 1,
    "alt": [
      "example6.png"
    ]
  },
  {
    "name": "war bear",
    "type": 1,
    "power": 13,
    "elementType": 1,
    "alt": [
      "example7.png"
    ]
  },
  {
    "name": "dragon",
    "type": 1,
    "power": 23,
    "elementType": 1,
    "alt": [
      "example8.jpg"
    ]
  },
  {
    "name": "bird cthulu",
    "type": 1,
    "power": 18,
    "elementType": 1,
    "alt": [
      "example9.jpg"
    ]
  },
  {
    "name": "giant octopus",
    "type": 1,
    "power": 13,
    "elementType": 1,
    "alt": [
      "example10.jpg"
    ]
  },
  {
    "name": "giant steam wurm",
    "type": 1,
    "power": 15,
    "elementType": 1,
    "alt": [
      "example11.jpg"
    ]
  },
  {
    "name": "mafia rat",
    "type": 1,
    "power": 14,
    "elementType": 1,
    "alt": [
      "example12.png"
    ]
  },
  {
    "name": "scrap savant rat",
    "type": 1,
    "power": 12,
    "elementType": 1,
    "alt": [
      "example13.png"
    ]
  },
  {
    "name": "steam elephant",
    "type": 1,
    "power": 22,
    "elementType": 1,
    "alt": [
      "example14.png"
    ]
  },
  {
    "name": "sky pirate",
    "type": 1,
    "power": 17,
    "elementType": 1,
    "alt": [
      "example15.png"
    ]
  },
  {
    "name": "cloud shade dragon",
    "type": 1,
    "power": 13,
    "elementType": 1,
    "alt": [
      "example16.jpg"
    ]
  },
  {
    "name": "steam leviethan",
    "type": 1,
    "power": 25,
    "elementType": 1,
    "alt": [
      "example17.jpg"
    ]
  },
  {
    "name": "goldenmane armore lion",
    "type": 1,
    "power": 18,
    "elementType": 1,
    "alt": [
      "example18.jpg"
    ]
  },
  {
    "name": "lightning kraken",
    "type": 1,
    "power": 25,
    "elementType": 1,
    "alt": [
      "example19.jpg"
    ]
  },
  {
    "name": "cool dragon",
    "type": 1,
    "power": 16,
    "elementType": 1,
    "alt": [
      "example20.jpg"
    ]
  },
  {
    "name": "octodad",
    "type": 1,
    "power": 14,
    "elementType": 1,
    "alt": [
      "example21.jpg"
    ]
  }
]