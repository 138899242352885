import { useEffect } from "react";

export const useKeyDown = (targetKey: string, callback: Function, deps?: any[]) => {
    function downHandler(e: any) {
        if (e.key.toLocaleLowerCase() === targetKey) {
            callback();
        }
    }
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
        };
    }, [callback]);
}