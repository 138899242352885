import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useStore } from "../../hooks/usestore";
import { IMessage } from "../../hooks/usewebsocket";
import { Phase } from "../../models/stella";

const Ready = observer(() => {
  const { lobbyStore: { lobbyCode }, stellaStore: { phase, myPlayer } } = useStore();
  const [ready, setReady] = useState(false)

  useEffect(() => {
      if (window.subscribeMessage) {
          window.subscribeMessage("stella_actions", "end_round", () => { console.log("end round - actions"); setReady(false); });
      }
      return () => {
          if (window.unSubscribeMessage) {
              window.unSubscribeMessage("stella_actions", "end_round");
          }
      }
  }, [])

  function onClickReady() {
      setReady(!ready);
      let message: IMessage = {
          lobbyCode: lobbyCode,
          type: "ready_stella",
          value: {
              ready: !ready,
              matrix: myPlayer!.matrix,
          }
      }
      window.sendWsMessage(message);
  }

  return (
      <>{phase === Phase.selection ? <button className="ready" type="button" onClick={onClickReady}>{ready ? "NOT READY" : "READY"}</button> : null}</>
  )
})

export default Ready;