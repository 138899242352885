import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useStore } from "../hooks/usestore";
import Stats from "three/examples/jsm/libs/stats.module";

const StatsInfo = () => {
    const { canvasStore: { addElement } } = useStore();
    const statsRef = useRef<HTMLDivElement>(null!);
    const st = useRef<Stats>()
    useEffect(() => {
        st.current = Stats();
        statsRef.current.appendChild(st.current.domElement);
        addElement({ id: "Stats", animate() { st.current!.update() }, })
    }, [])
    return (
        <div ref={statsRef}></div>
    )
}

export default observer(StatsInfo);