// (TODO: Insert cardback in an indexdb to retrive it faster)
import cardback from "../assets/images/elementsclash/cardback.png";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Origin } from "../../../models/draggable";
import useDrag from "../../../hooks/usedrag";
import { ICard } from "../../../models/elementsclash";
import { useStore } from "../../../hooks/usestore";
import "../../../styles/games/elements_clash/card.scss";

interface IProps {
  card: ICard,
  flipped?: boolean,
  draggable?: boolean,
  rotation?: number,
  origin: Origin,
  // style?: React.CSSProperties,
}

const Card = ({ card, flipped = false, draggable = false, origin, rotation }: IProps) => {
  const { elementsClashStore: { grabbedCard, setGrabbedCard, playerHand, setPlayerHand } } = useStore();
  const [content, setContent] = useState<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement>(null!);
  // const contentRef = useRef<HTMLDivElement>(null!);

  // console.log("Card", card, contentRef.current)

  useEffect(() => {
    if (content) {
      console.log("Card found")
    }
  }, [content])

  useDrag(content, [draggable], {
    onPointerDown: startGrabbing,
  })

  function startGrabbing(e: PointerEvent) {
    console.log("Start grabbing")
    if (draggable) {
      // @ts-ignore
      e.target.releasePointerCapture(e.pointerId);
      cardRef.current.style.pointerEvents = "none";
      setGrabbedCard({ object: card, origin: origin, pointerUpCallback: endGrabbing, pointerMoveCallback: moveCard });
    }
  }

  function endGrabbing(e: PointerEvent) {
    if (draggable) {
      cardRef.current.style.transform = `translateX(0px) translateY(0px)`;
      if (rotation) {
        if (content) content.style.transform = "";
      }
      cardRef.current.style.pointerEvents = "unset";
      setGrabbedCard(null);
    }
  }

  function moveCard(e: PointerEvent, deltaX: number, deltaY: number) {
    cardRef.current.style.transform = `translateX(${deltaX}px) translateY(${deltaY}px)`;
    if (rotation) {
      if (content) content.style.transform = `rotate(${360 - rotation}deg)`;
    }
  }

  return (
    <div ref={cardRef} custom-name={card.name} className={`card-handler`}>
      <div className={`card`} custom-name={card.name} style={rotation ? { transform: `rotate(${rotation}deg)` } : undefined}>
        <div ref={setContent} custom-name={card.name} className={`content ${flipped ? "flipped" : ""}`}>
          {/* Front */}
          <div className="front">
            <img className="card-image" alt={card.name} src={`${process.env.PUBLIC_URL}/assets/images/elementsclash/cards/creatures/${card.artwork}`} />
          </div>
          {/* Back */}
          <div className="back">
            {/* <img className="card-image" alt={""} src={cardback} /> */}
            <div className="inner">
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Card);