import { action, makeObservable, observable } from "mobx";
import { IPosition } from "../hooks/usegesture";
import { ICard, IPlayer, Phase } from "../models/stella";

export class StellaStore {
    explorator: string = "";
    round: number = 1;
    word: string = "";
    cards: ICard[][] = [];
    myPlayer: IPlayer | null = null;
    players: IPlayer[] = [];
    phase: Phase = Phase.selection;
    constructor() {
        makeObservable(this, {
            explorator: observable,
            round: observable,
            word: observable,
            cards: observable,
            myPlayer: observable,
            players: observable,
            phase: observable,
            setupStella: action,
            updateRound: action,
            updateWord: action,
            updateCards: action,
            updateSingleCard: action,
            updatePlayer: action,
            updateMyMatrix: action,
            updatePlayers: action,
            updateExplorator: action,
            updatePhase: action,
        })
    }
    setupStella = (gameData: any, myPlayerId: string) => {
        this.phase = gameData.phase;
        this.explorator = gameData.explorator;
        this.round = gameData.round;
        this.word = gameData.word;
        this.cards = gameData.cards;
        this.myPlayer = gameData.players.find((x: IPlayer) => x.playerID === myPlayerId);
        this.players = gameData.players;
    }
    updateMyMatrix = (x: number, y: number) => {
        if (this.myPlayer) { this.myPlayer.matrix[x][y] = this.myPlayer.matrix[x][y] === 0 ? 1 : 0 }
    }
    updatePlayer = (player: IPlayer) => {
        let _players = [...this.players]
        let index = _players.findIndex(x => x.playerID === player.playerID);
        _players[index] = player;
        this.players = _players;
    }
    updatePlayers = (players: IPlayer[], myPlayerId: string) => {
        this.players = players;
        this.myPlayer = players.find((x: IPlayer) => x.playerID === myPlayerId)!;
    }
    updatePhase = (phase: Phase) => {
        this.phase = phase;
    }
    updateRound = (round: number) => {
        this.round = round;
    }
    updateCards = (cards: ICard[][]) => {
        this.cards = cards;
    }
    updateSingleCard = (pos: IPosition, card: ICard) => {
        this.cards[pos.x][pos.y] = card;
    }
    updateWord = (word: string) => {
        this.word = word;
    }
    updateExplorator = (explorator: string) => {
        this.explorator = explorator;
    }
}