import { createContext, ReactNode, useLayoutEffect, useState } from "react";
import CanvasElement from "./canvaselement";

interface IState {
    canvas: HTMLCanvasElement | null,
    context: CanvasRenderingContext2D | null,
    elements: CanvasElement[],
    setupCanvas: (canvas: HTMLCanvasElement) => void,
    clearCanvas: () => void,
    addElement: (element: CanvasElement) => void,
    updateElement: (element: CanvasElement) => void,
    removeElement: (element: CanvasElement) => void,
}

const initState: IState = {
    canvas: null,
    context: null,
    elements: [],
    setupCanvas: () => { },
    clearCanvas: () => { },
    addElement: () => { },
    updateElement: () => { },
    removeElement: () => { },
}

export const CanvasCtx = createContext(initState);

interface IProps {
    children?: ReactNode | ReactNode[]
}

const CanvasContext = ({ children }: IProps) => {
    const [state, setState] = useState<IState | null>(null);

    useLayoutEffect(() => {
        setState(prev => {
            let state = { ...initState };
            state.setupCanvas = setupCanvas;
            state.clearCanvas = clearCanvas;
            state.addElement = addElement;
            state.updateElement = updateElement;
            state.removeElement = removeElement;
            return state;
        });
    }, [])

    const setupCanvas = (canvas: HTMLCanvasElement) => {
        console.log("setup canvas called")
        setState(prev => {
            if (prev) {
                let state = { ...prev };
                state.elements = [];
                state.canvas = canvas;
                state.context = canvas.getContext("2d");
                return state;
            }
            return null;
        });
    }

    const clearCanvas = () => {
        setState(prev => {
            if (prev) {
                let state = { ...prev };
                state.elements = [];
                state.canvas = null;
                state.context = null;
                return state;
            }
            return null
        });
    }

    const addElement = (element: CanvasElement) => {
        setState(prev => {
            if (prev) {
                let state = { ...prev };
                state.elements.push(element);
                state.elements = state.elements.sort((a, b) => a.layer < b.layer ? -1 : 1);
                state.elements.forEach(x => x.onCreate());
                return state;
            }
            return null;
        });
    }

    const updateElement = (element: CanvasElement) => {
        setState(prev => {
            if (prev) {
                let state = { ...prev };
                let index = state.elements.findIndex(x => x.elementID === element.elementID);
                if (index > -1) {
                    state.elements[index] = element;
                }
                state.elements = [...state.elements];
                return state;
            }
            return null;
        });
    }

    const removeElement = (element: CanvasElement) => {
        setState(prev => {
            if (prev) {
                let state = { ...prev };
                let index = state.elements.findIndex(x => x.elementID === element.elementID);
                if (index > -1) {
                    state.elements.splice(index, 1);
                }
                return state;
            }
            return null;
        });
    }

    return (
        state ?
            <CanvasCtx.Provider value={state}>
                {children}
            </CanvasCtx.Provider>
            :
            null
    )
}

export default CanvasContext;