import { useEffect, useRef, useState } from "react";
import useDrag from "./usedrag";

// To be improved!
const useMousePosition = () => {
    const mousePosition = useRef<{ x: number, y: number }>({ x: 0, y: 0 })
    const previousMousePosition = useRef<{ x: number, y: number } | null>(null)
    const [deltaMousePosition, setDeltaMousePosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

    useDrag(document, [], {
        onPointerDown: handlePointerDown,
        onPointerMove: handlePointerMove,
        onPointerUp: handlePointerUp,
    })

    function handlePointerDown() {
        mousePosition.current = { x: 0, y: 0 };
        previousMousePosition.current = null;
        setDeltaMousePosition({ x: 0, y: 0 });
    }

    function handlePointerMove(e: PointerEvent) {
        mousePosition.current.x = e.pageX;
        mousePosition.current.y = e.pageY;
        if (previousMousePosition.current) {
            let _previousMousePosition = previousMousePosition.current;
            setDeltaMousePosition(prev => {
                if (previousMousePosition.current) {
                    prev.x += mousePosition.current.x - _previousMousePosition.x;
                    prev.y += mousePosition.current.y - _previousMousePosition.y;
                }
                return prev;
            })
        } else {
            previousMousePosition.current = { x: 0, y: 0 }
        }
        previousMousePosition.current.x = e.pageX;
        previousMousePosition.current.y = e.pageY;
    }

    function handlePointerUp() {
        mousePosition.current = { x: 0, y: 0 };
        previousMousePosition.current = null;
        setDeltaMousePosition({ x: 0, y: 0 });
    }

    return {
        deltaMousePosition
    };
};

export default useMousePosition;
