import _ from "lodash";
import { useState, useRef, useEffect, CSSProperties } from "react";
import { invertColor } from "../../functions/utility";
import { IMessage } from "../../hooks/usewebsocket";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

interface INotification {
  id?: string,
  color: string,
  message: string,
}

const demoMessage = { message: "TEST message very long player 1 fallen", color: "#ff3212" }

const Notifications = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "notification_stella", handleNotification);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "notification_stella");
      }
    }
  }, [])

  function handleNotification(message: IMessage) {
    setNotifications(prev => {
      prev.push({ ...message.value, id: _.uniqueId() });
      return prev;
    })
    if (timeout.current) clearTimeout(timeout.current);
    removeNotification();
  }

  function removeNotification() {
    timeout.current = setTimeout(() => {
      setNotifications(prev => {
        let notifications = prev.slice(1);
        if (notifications.length > 0) removeNotification();
        return notifications;
      });
    }, 8000);
  }

  return (
    <div className="notifications">
      {
        notifications.map(x => <Notification key={x.id} notification={x} handleRemove={() => {
          if (timeout.current) clearTimeout(timeout.current);
          setNotifications(prev => {
            let notifications = [...prev];
            notifications = notifications.filter(y => y.id !== x.id);
            if (notifications.length > 0) removeNotification();
            return notifications;
          })
        }} />)
      }
    </div>
  )
}

export default Notifications;

interface INotificationProps {
  notification: INotification,
  handleRemove: () => void,
}

const Notification = ({ notification, handleRemove }: INotificationProps) => {
  return (
    <div className="item" style={{ "--notification-color": notification.color, "--text-color": invertColor(notification.color, true) } as CSSProperties} onClick={handleRemove}>
      {notification.message}<CloseIcon className="close" />
    </div>
  )
}