import { useCallback, useEffect, useRef } from "react";
import * as THREE from "three";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { useStore } from "../../hooks/usestore";
import { observer } from "mobx-react-lite";

// Bloom pass layers:
export const ENTIRE_SCENE = 0, BLOOM_SCENE = 1;

const bloomVertexShader = `
    varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
`

const bloomFragmentShader = `
    uniform sampler2D baseTexture;
    uniform sampler2D bloomTexture;

    varying vec2 vUv;

    void main() {
        gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
    }
`

// Constants used by bloom pass to check if apply bloom to an element:
const bloomLayer = new THREE.Layers(); bloomLayer.set(BLOOM_SCENE);
const darkMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
interface IMaterial {
    [key: string]: any
}
const materials: IMaterial = {};

interface IProps {
    priority: number,
    threshold?: number,
    strength?: number,
    radius?: number
}

const BloomPass = ({ priority, threshold = 0, strength = 3.5, radius = 0.25 }: IProps) => {
    const { canvasStore: { scene, camera, renderer, renderPass, finalComposer, addElement, addPass } } = useStore();
    const bloomComposerRef = useRef<EffectComposer>();

    useEffect(() => {
        if (renderer && renderPass && finalComposer) {
            const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
            bloomPass.threshold = threshold;
            bloomPass.strength = strength;
            bloomPass.radius = radius;

            const bloomComposer = new EffectComposer(renderer);
            bloomComposer.renderToScreen = false;
            bloomComposer.addPass(renderPass);
            bloomComposer.addPass(bloomPass);
            bloomComposerRef.current = bloomComposer;

            const finalPass = new ShaderPass(
                new THREE.ShaderMaterial({
                    uniforms: {
                        baseTexture: { value: null },
                        bloomTexture: { value: bloomComposer.renderTarget2.texture }
                    },
                    vertexShader: bloomVertexShader,
                    fragmentShader: bloomFragmentShader,
                    defines: {}
                }), 'baseTexture'
            );
            finalPass.needsSwap = true;

            addPass({ priority: priority, pass: finalPass });

            addElement({ id: "BloomPass", animate: () => renderBloom(true) })
        }
    }, [renderer, renderPass, finalComposer])

    const renderBloom = useCallback((mask: boolean) => {
        if (scene && camera) {
            if (mask === true) {
                scene.traverse(darkenNonBloomed);
                bloomComposerRef.current && bloomComposerRef.current.render();
                scene.traverse(restoreMaterial);
            } else {
                camera.layers.set(BLOOM_SCENE);
                bloomComposerRef.current && bloomComposerRef.current.render();
                camera.layers.set(ENTIRE_SCENE);
            }
        }
    }, [scene, camera])

    function darkenNonBloomed(obj: any) {
        if (obj.isMesh && bloomLayer.test(obj.layers) === false) {
            materials[obj.uuid] = obj.material;
            obj.material = darkMaterial;
        }
    }

    function restoreMaterial(obj: any) {
        if (materials[obj.uuid]) {
            obj.material = materials[obj.uuid];
            delete materials[obj.uuid];
        }
    }

    return (
        <></>
    )
}

export default observer(BloomPass);