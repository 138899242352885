import { action, makeObservable, observable } from "mobx";
import { Action } from "../models/character";
import { IPlayer } from "../models/player";
import { IUser } from "../models/user";

// interface 
export default class WebSocketStore {
    isConnected: boolean = false;
    constructor() {
        makeObservable(this, {
            isConnected: observable,
            setIsConnected: action,
        });
    }
    setIsConnected = (isConnected: boolean) => {
        this.isConnected = isConnected;
    }
}