import { action, makeObservable, observable } from "mobx";
import { IPlayer } from "../models/player";

export default class PlayerStore {
    currentPlayer: IPlayer | null = null;
    playerList: IPlayer[] = [];
    constructor() {
        makeObservable(this, {
            currentPlayer: observable,
            addPlayer: action,
            setCurrentPlayer: action,
        });
    }
    addPlayer = (playerList: IPlayer[]) => {
        this.playerList = playerList;
    }
    setCurrentPlayer = (currentPlayer: IPlayer) => {
        this.currentPlayer = currentPlayer;
    }
}