import { observer } from "mobx-react-lite";
import { useStore } from "../../../hooks/usestore";
import { Origin } from "../../../models/draggable";
import Card from "../components/card";
import "../../../styles/games/elements_clash/deck.scss";

interface IProps {
}

const Deck = ({ }: IProps) => {
  const { elementsClashStore: { deck } } = useStore();

  return (
    <div className="deck">
      <div className="cards">
        {
          deck.slice(0, 5).map(x => {
            return <Card key={x.name} card={x} origin={Origin.deck} flipped />
          })
        }
      </div>
    </div>
  )
}

export default observer(Deck);