import { useCallback, useEffect, useRef } from "react";
import { useStore } from "../hooks/usestore";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { observer } from "mobx-react-lite";

const Camera = () => {
    const { canvasStore: { scene, camera, renderer, addElement } } = useStore();
    const controlsRef = useRef<OrbitControls | null>(null!);
    useEffect(() => {
        if (scene && camera && renderer) {
            const controls = new OrbitControls(camera, renderer.domElement);
            controlsRef.current = controls;
            addElement({ id: "OrbitControls", obj: controls, animate: animate });
        }
    }, [scene, camera, renderer])
    const animate = useCallback(() => { if (controlsRef.current) controlsRef.current.update(); }, []);
    return (
        <></>
    )
}

export default observer(Camera);