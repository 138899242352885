import { useEffect, useRef, useState } from "react";

export interface IPosition {
    x: number,
    y: number
}

function useGesture(callbackDown?: (pos: IPosition) => void, callbackMove?: (delta: IPosition) => void, callbackUp?: (pos: IPosition, e: PointerEvent) => void, callbackPinch?: (delta: number, wheel?: boolean) => void) {
    const savedPos = useRef<IPosition>()
    const grabbed = useRef(false);
    const pointers = useRef<PointerEvent[]>([]);
    const diff = useRef(0);
    useEffect(() => {
        function onPointerDown(e: PointerEvent) {
            grabbed.current = true;
            savedPos.current = { x: e.x, y: e.y }
            pointers.current.push(e);
            callbackDown && callbackDown({ x: e.x, y: e.y });
        }
        function onPointerMove(e: PointerEvent) {
            if (grabbed.current && savedPos.current) {
                const index = pointers.current.findIndex((cache) => cache.pointerId === e.pointerId);
                if (index > -1) {
                    pointers.current[index] = e;
                }

                if (pointers.current.length === 2) {
                    const xDiff = Math.abs(pointers.current[0].clientX - pointers.current[1].clientX);
                    const yDiff = Math.abs(pointers.current[0].clientY - pointers.current[1].clientY);
                    let currentDiff = xDiff + yDiff;
                    if (diff.current > 0) {
                        callbackPinch && callbackPinch(currentDiff - diff.current);
                    }
                    diff.current = currentDiff;
                } else {
                    let delta = { x: e.x - savedPos.current.x, y: e.y - savedPos.current.y }
                    callbackMove && callbackMove(delta);
                }
            }
        }
        function onPointerUp(e: PointerEvent) {
            pointers.current = pointers.current.filter((x) => x.pointerId !== e.pointerId);
            if (pointers.current.length < 2) {
                diff.current = 0;
            }
            if (pointers.current.length === 0 && grabbed.current && savedPos.current) {
                let delta = { x: e.x - savedPos.current.x, y: e.y - savedPos.current.y }
                grabbed.current = false;
                savedPos.current = undefined;
                callbackUp && callbackUp(delta, e);
            }
        }
        function onWheel(e: WheelEvent) {
            callbackPinch && callbackPinch(e.deltaY * -0.1, true)
        }
        document.addEventListener('pointerdown', onPointerDown);
        document.addEventListener('pointermove', onPointerMove);
        document.addEventListener('pointerup', onPointerUp);
        document.addEventListener('wheel', onWheel);
        return () => {
            document.removeEventListener('pointerdown', onPointerDown);
            document.removeEventListener('pointermove', onPointerMove);
            document.removeEventListener('pointerup', onPointerUp);
            document.removeEventListener('wheel', onWheel);
        }
    }, [callbackDown, callbackMove, callbackUp])
}

export default useGesture;