export interface ICharacter {
    type: CharacterType,
    avaiableActions: Action[],
}

export enum CharacterType {
    Warrior,
    Witch,
    Master
}

export enum Action {
    move,
    explore,
    attack
}