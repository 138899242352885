import { useState, useEffect } from 'react';

interface IOptions {
  onPointerLeave?: (e: PointerEvent) => void,
  onPointerDown?: (e: PointerEvent) => void,
  onPointerUp?: (e: PointerEvent) => void,
  onPointerMove?: (e: PointerEvent) => void,
  onDrag?: (e: PointerEvent) => void,
}

const useDrag = (elem: any, deps: any = [], options: IOptions) => {
  const {
    onPointerLeave = () => { },
    onPointerDown = () => { },
    onPointerUp = () => { },
    onPointerMove = () => { },
    onDrag = () => { },
  } = options;

  const [isDragging, setIsDragging] = useState(false);

  const handlePointerLeave = (e: PointerEvent) => {
    onPointerLeave(e);
  }

  const handlePointerDown = (e: PointerEvent) => {
    setIsDragging(true);

    onPointerDown(e);
  };

  const handlePointerUp = (e: PointerEvent) => {
    setIsDragging(false);

    onPointerUp(e);
  };

  const handlePointerMove = (e: PointerEvent) => {
    onPointerMove(e);

    if (isDragging) {
      onDrag(e);
    }
  };

  useEffect(() => {
    console.log("elem", elem)
    if (elem) {
      elem.addEventListener('pointerleave', handlePointerLeave);
      elem.addEventListener('pointerdown', handlePointerDown);
      elem.addEventListener('pointerup', handlePointerUp);
      elem.addEventListener('pointermove', handlePointerMove);

      return () => {
        elem.removeEventListener('pointerleave', handlePointerLeave);
        elem.removeEventListener('pointerdown', handlePointerDown);
        elem.removeEventListener('pointerup', handlePointerUp);
        elem.removeEventListener('pointermove', handlePointerMove);
      };
    }
    return () => { };
  }, [elem, ...deps, options, isDragging]);

  return { isDragging };
};

export default useDrag;