import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKeyDown } from "../hooks/usekeydown";
import { useStore } from "../hooks/usestore";
import { IPlayer, Phase } from "../models/stella";
import { IPosition } from "../hooks/usegesture";
import Demo from "../assets/images/stella/demo.png";
import { IMessage } from "../hooks/usewebsocket";
import _ from "lodash";
// Updated imports:
import Board from "../games/stella_updated/board";
import Chat from "../games/stella_updated/chat";
import Leaderboard from "../games/stella_updated/leaderboard";
import Info from "../games/stella_updated/info";
import Word from "../games/stella_updated/word"; 
import "../styles/pages/stella_updated.scss";

const Stella = () => {
  const { lobbyStore: { setMyPlayerID }, stellaStore: { myPlayer, players, setupStella, updatePlayer, updatePlayers, updateExplorator, updatePhase, updateWord, updateRound, updateCards } } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "start_explorer_phase_stella", handleStartExplorerPhase);
      window.subscribeMessage("stella", "end_round", handleEndRound);
      window.subscribeMessage("stella", "end_game", handleEndGame);
      window.subscribeMessage("stella", "game_data_stella", handleGameData);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "start_explorer_phase_stella")
        window.unSubscribeMessage("stella", "end_round");
        window.unSubscribeMessage("stella", "end_game");
        window.unSubscribeMessage("stella", "game_data_stella");
      }
    }
  }, [myPlayer])

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "connected", handleReconnect);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "connected");
      }
    }
  }, [])

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("stella", "update_player_status_stella", handleUpdatePlayerStatus);
      window.subscribeMessage("stella", "post_card_selection_stella", handlePostCardSelection);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("stella", "update_player_status_stella")
        window.unSubscribeMessage("stella", "post_card_selection_stella")
      }
    }
  }, [players])

  useKeyDown("1", () => {
    // setMyPlayerID("P3");
    setupStella({
      word: "Friendship",
      round: 1,
      explorator: "P3",
      cards: [[{ image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }], [{ image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }], [{ image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }, { image: Demo }]],
      phase: Phase.selection,
      players: [
        { playerID: "P1", nickname: "William Bardon", color: "#c12626", points: 0, steps: 1 },
        { playerID: "P2", nickname: "Matteo Peroni", color: "#72c227", points: 0, steps: 1 },
        { playerID: "P3", nickname: "Dario Freschini", color: "#2791c2", points: 0, steps: 1, matrix: [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]] },
        { playerID: "P4", nickname: "Giulia ", color: "#5d27c2", points: 0, steps: 3 },
        { playerID: "P5", nickname: "Lucino Michelo ", color: "#5d27c2", points: 0, steps: 2 },
        { playerID: "P6", nickname: "Giuseppo fraschino ", color: "#5d27c2", points: 0, steps: 3 },
        { playerID: "P7", nickname: "Cappellano Paolo ", color: "#5d27c2", points: 0, steps: 3 },
        { playerID: "P8", nickname: "Altoprete Davide ", color: "#5d27c2", points: 0, steps: 3 },
        { playerID: "P9", nickname: "Gabriele Capitan Marmotta ", color: "#5d27c2", points: 0, steps: 3 },
      ]
    }, "P3")
  })

  // Message received:
  // Update players status
  function handleUpdatePlayerStatus(message: IMessage) {
    let player = players.find(x => x.playerID === message.value.playerID);
    if (player) {
      player.ready = message.value.ready;
      updatePlayer(player)
    }
  }
  // Set everything for the exploration phase
  function handleStartExplorerPhase(message: IMessage) {
    updatePhase(Phase.exploration);
    updateExplorator(message.value.explorator);
    let players: IPlayer[] = message.value.players;
    if (myPlayer) {
      let myIndex = players.findIndex(x => x.playerID === myPlayer.playerID)
      if (myIndex > -1) {
        players[myIndex].matrix = myPlayer.matrix;
      }
      updatePlayers(players, myPlayer.playerID);
    }
  }
  // Update players points, 
  function handlePostCardSelection(message: IMessage) {
    updateExplorator(message.value.explorator);
    let card: IPosition = message.value.cardToRemove;
    let players: IPlayer[] = message.value.players;
    if (myPlayer) {
      myPlayer.matrix[card.x][card.y] = 0;
      let myIndex = players.findIndex(x => x.playerID === myPlayer.playerID)
      if (myIndex > -1) {
        players[myIndex].matrix = myPlayer.matrix;
      }
      updatePlayers(players, myPlayer.playerID);
    }
  }
  // Handle end of the round
  function handleEndRound(message: IMessage) {
    updateWord(message.value.word);
    updateRound(message.value.round);
    updateExplorator(message.value.explorator);
    updateCards(message.value.cards);
    updatePhase(Phase.selection);
    let players: IPlayer[] = message.value.players;
    if (myPlayer) {
      let myIndex = players.findIndex(x => x.playerID === myPlayer.playerID)
      if (myIndex > -1) {
        players[myIndex].matrix = myPlayer.matrix;
        for (let x = 0; x < 3; x++) for (let y = 0; y < 5; y++) players[myIndex].matrix[x][y] = 0;
      }
      updatePlayers(players, myPlayer.playerID);
    }
  }
  // Handle end of the game
  function handleEndGame(message: IMessage) {
    let players: IPlayer[] = message.value.players;
    if (myPlayer) {
      let myIndex = players.findIndex(x => x.playerID === myPlayer.playerID)
      if (myIndex > -1) {
        players[myIndex].matrix = myPlayer.matrix;
        for (let x = 0; x < 3; x++) for (let y = 0; y < 5; y++) players[myIndex].matrix[x][y] = 0;
      }
      updatePlayers(players, myPlayer.playerID);
    }
    navigate("/leaderboard")
  }
  // Handle refresh and temporary disconnection from the websocket
  function handleReconnect(message: IMessage) {
    // Try to reconnect the current game:
    let prevClientID = localStorage.getItem("previousClientID");
    if (prevClientID) {
      let message: IMessage = {
        lobbyCode: localStorage.getItem("lobbyCode") || "",
        type: "reconnect_stella",
        value: prevClientID
      }
      window.sendWsMessage(message);
    }
  }
  // Retrive the game data to restore the previous situation
  function handleGameData(message: IMessage) {
    let gameData = message.value.data;
    updateWord(gameData.word);
    updateRound(gameData.round);
    updateExplorator(gameData.explorator);
    updateCards(gameData.cards);
    updatePhase(gameData.phase);
    setMyPlayerID(message.value.myPlayerID);
    let players: IPlayer[] = gameData.players;
    let myIndex = players.findIndex(x => x.playerID === message.value.myPlayerID)
    if (myIndex > -1) {
      if (myPlayer) { players[myIndex].matrix = myPlayer.matrix; }
      else { players[myIndex].matrix = [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]]; }
    }
    updatePlayers(players, message.value.myPlayerID);
  }

  return (
    <>
      <main className="stella">
        <Word />
        <Board />
        <Chat />
        <Leaderboard />
        <Info />
      </main>
    </>
  )
}

export default observer(Stella);