import { action, makeObservable, observable } from "mobx";
import { Action } from "../models/character";
import { IPlayer } from "../models/player";
import { IUser } from "../models/user";

// interface 
export default class LobbyStore {
    myClientId?: string;
    myPlayerId?: string;
    lobbyCode?: string;
    lobbyUsers: IUser[] = [];
    constructor() {        
        makeObservable(this, {
            myClientId: observable,
            myPlayerId: observable,
            lobbyCode: observable,
            lobbyUsers: observable,
            setMyClientId: action,
            setLobbyCode: action,
            setLobbyUsers: action,
        });
    }
    setMyClientId = (myClientId?: string) => {
        this.myClientId = myClientId;
    }
    setMyPlayerID = (myPlayerId?: string) => {
        this.myPlayerId = myPlayerId;
    }
    setLobbyCode = (lobbyCode?: string) => {
        this.lobbyCode = lobbyCode;
    }
    setLobbyUsers = (users: IUser[]) => {
        this.lobbyUsers = users;
    }
}