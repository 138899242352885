import { useEffect, useState } from "react";
import { ReactComponent as SwordsIcon } from "../assets/icons/swords.svg"
import { IRound } from "../../../models/elementsclash";
import { useStore } from "../../../hooks/usestore";
import "../../../styles/games/elements_clash/info.scss";

interface IProps {
}

const Info = ({ }) => {
    const { elementsClashStore: { currentRound, setCurrentRound } } = useStore();
    const [rounds, setRounds] = useState<IRound[]>([{ title: "Round 1" }, { title: "Round 2" }, { title: "Round 3" }])



    useEffect(() => {
        if (currentRound) {
            setRounds(prev => {
                let updRounds = [...prev];
                let updRound = updRounds.find(x => x.title === currentRound.title);
                if (updRound) {
                    updRound.result = currentRound.result;
                }
                return updRounds;
            });
        }
    }, [currentRound])

    return (
        <div className="info">
            <div className="rounds">
                {
                    rounds.length > 0 && rounds.map(x => {
                        return (
                            <div key={x.title} className="round">
                                {x.title}
                                {
                                    // currentRound.title === x.title &&
                                    // <SwordsIcon className="icon" />
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Info;