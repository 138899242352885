import { IPosition } from "../../hooks/usegesture";

export default class CanvasElement {
    elementID: string;
    layer: number;
    position: IPosition = { x: 0, y: 0 };
    type: string;
    tag?: string;
    context: CanvasRenderingContext2D;
    additionalData: any;
    handleCreate: (element: CanvasElement) => void;
    handleUpdate: (element: CanvasElement, deltaTime: number) => void;

    constructor(
        elementID: string,
        layer: number,
        context: CanvasRenderingContext2D,
        type: string,
        handleCreate: (element: CanvasElement) => void,
        handleUpdate: (element: CanvasElement, deltaTime: number) => void,
        additionalData?: any,
        tag?: string) {
        // Initialize:
        this.elementID = elementID;
        this.layer = layer;
        this.context = context;
        this.type = type;
        this.additionalData = additionalData;
        this.tag = tag;
        this.handleCreate = handleCreate;
        this.handleUpdate = handleUpdate;
    }
    onCreate() {
        this.handleCreate(this);
    }
    onUpdate(deltaTime: number) {
        this.handleUpdate(this, deltaTime);
    }
}