import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../hooks/usestore";
import { IMessage } from "../hooks/usewebsocket";
import { IUser } from "../models/user";
// @ts-ignore
import Warrior from "../assets/characters/warrior.png";
// @ts-ignore
import Witch from "../assets/characters/witch.png";
import { CharacterType } from "../models/character";
import { ILobby } from "../models/lobby";
// @ts-ignore
import { ReactComponent as Ball } from "../assets/icons/ball.svg";
import "../styles/pages/lobby.scss";

const Lobby = () => {
  const { webSokcetStore: { isConnected }, lobbyStore: { myClientId, setMyPlayerID, setLobbyCode, setLobbyUsers }, stellaStore: { setupStella } } = useStore();
  const [users, setUsers] = useState<IUser[]>([]);
  const [lobbies, setLobbies] = useState<ILobby[]>([]);
  const [ready, setReady] = useState(false);
  const [internalLobbyCode, setInternalLobbyCode] = useState("");
  const lobbyField = useRef<HTMLInputElement>(null!);
  const nickname = useRef<HTMLInputElement>(null!);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("lobby", "user_joined", handleUserJoined);
      window.subscribeMessage("lobby", "user_list", handleUserList);
      window.subscribeMessage("lobby", "user_left", handleUserLeft);
      window.subscribeMessage("lobby", "user_updated", handleUserUpdate);
      window.subscribeMessage("lobby", "lobby_list", handleLobbyList);
      window.subscribeMessage("lobby", "lobby_created", handleLobbyCreated);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("lobby", "user_joined")
        window.unSubscribeMessage("lobby", "user_list")
        window.unSubscribeMessage("lobby", "user_left")
        window.unSubscribeMessage("lobby", "user_updated")
        window.unSubscribeMessage("lobby", "lobby_list")
        window.unSubscribeMessage("lobby", "lobby_created")
      }
    }
  }, [])

  useEffect(() => {
    window.sendWsMessage({ type: "lobby_list" })
  }, [isConnected])

  useEffect(() => {
    if (window.subscribeMessage) {
      // Stella: 
      window.subscribeMessage("lobby", "game_started_stella", handleGameStartedStella);
    }
    return () => {
      if (window.unSubscribeMessage) {
        // Stella: 
        window.unSubscribeMessage("lobby", "game_started_stella")
      }
    }
  }, [users])

  // Message to send:
  // Create or join lobbies:
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setUsers([]);
    setLobbyCode(lobbyField.current.value);
    setInternalLobbyCode(lobbyField.current.value)
    localStorage.setItem("lobbyCode", lobbyField.current.value);
    let message: IMessage = {
      lobbyCode: lobbyField.current.value,
      // @ts-ignore
      type: e.nativeEvent && e.nativeEvent.submitter && e.nativeEvent.submitter.id === "Create" ? "create_lobby" : "join_lobby",
      value: lobbyField.current.value
    }
    window.sendWsMessage(message);
  }
  function handleJoinLobby(code: string) {
    setLobbyCode(code);
    setInternalLobbyCode(code);
    localStorage.setItem("lobbyCode", code);
    let message: IMessage = {
      lobbyCode: code,
      type: "join_lobby",
      value: code
    }
    window.sendWsMessage(message);
  }
  function handleLeaveLobby() {
    let message: IMessage = {
      lobbyCode: internalLobbyCode,
      type: "leave_lobby",
    }
    setInternalLobbyCode("");
    window.sendWsMessage(message);
  }
  // Update nickname and ready state:
  function handleNickname(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let message: IMessage = {
      lobbyCode: internalLobbyCode,
      type: "update_user",
      value: { nickname: nickname.current.value, ready: ready }
    }
    window.sendWsMessage(message);
  }
  // Update nickname and ready state:
  function handleStatusChange() {
    let status = !ready;
    setReady(status);
    let message: IMessage = {
      lobbyCode: internalLobbyCode,
      type: "update_user",
      value: { nickname: nickname.current.value, ready: status }
    }
    window.sendWsMessage(message)
  }
  // Stella:
  // Send to all clients to start the stella game:
  function handleStella() {
    window.sendWsMessage({ lobbyCode: internalLobbyCode, type: "start_game_stella" })
  }

  function handleElementsClash() { 
    navigate("/elements-clash")
  }

  // Message received
  // Update user list adding the new user that joined the lobby:
  function handleUserJoined(message: IMessage) {
    let client = message.value.client;
    setUsers(prev => {
      let users = [...prev];
      users.push({
        clientID: client.clientID,
        nickname: client.nickname,
        playerId: client.playerId,
        color: client.color
      });
      if (message.value.host) {
        users.forEach(x => {
          x.host = x.clientID === message.value.host;
        })
      }
      return users;
    })
  }
  // Retrive user list and update current state:
  function handleUserList(message: IMessage) {
    setUsers(message.value.clients.map((x: IUser) => {
      return {
        clientID: x.clientID,
        nickname: x.nickname,
        playerId: x.playerId,
        color: x.color,
        myUser: x.myUser,
        host: message.value.host === x.clientID
      }
    }))
  }
  // Remove the user that left from the list:
  function handleUserLeft(message: IMessage) {
    setUsers(prev => {
      let users = [...prev];
      let index = users.findIndex(x => x.clientID === message.value.client.clientID);
      if (index > -1) {
        users.splice(index, 1);
      }
      if (message.value.host) {
        index = users.findIndex(x => x.clientID === message.value.host);
        if (index > -1) {
          users[index].host = true;
        }
      }
      return users;
    })
  }
  // Update the specified user in the current state:
  function handleUserUpdate(message: IMessage) {
    setUsers(prev => {
      let users = [...prev];
      let index = users.findIndex(x => x.clientID === message.value.clientID);
      if (index > -1) {
        users[index].nickname = message.value.nickname
        users[index].ready = message.value.ready
      }
      return users;
    })
  }
  // Handle lobby list
  function handleLobbyList(message: IMessage) {
    setLobbies(message.value.lobbies);
  }
  function handleLobbyCreated(message: IMessage) {
    setLobbies(prev => {
      let lobbies = [...prev];
      lobbies.push(message.value.lobby);
      return lobbies;
    })
  }
  // Stella:
  // Start stella game
  function handleGameStartedStella(message: IMessage) {
    let myUser = users.find(x => x.clientID === myClientId);
    setMyPlayerID(myUser?.playerId);
    setupStella(message.value, myUser?.playerId || "");
    navigate("/stella-updated")
    //   navigate("/stella")
  }

  return (
    <div className="lobby">
      <h2>NICKNAME:</h2>
      <form onSubmit={handleNickname} className="form">
        <input required ref={nickname} placeholder="nickname"></input>
        <button id="Save" type="submit">Save</button>
      </form>
      {
        !internalLobbyCode ?
          <>
            <h2>CREATE LOBBY:</h2>
            <form onSubmit={handleSubmit} className="form">
              <input required ref={lobbyField} placeholder="code"></input>
              <button id="Create" type="submit">Create</button>
              <button id="Enter" type="submit">Enter</button>
            </form>
            <h2>LOBBIES:</h2>
            <div className="lobbies">
              <div className="list">
                {lobbies.map(x => <div key={x.code} className={`row`}><Ball className="green" /><span>{x.code}</span>{!x.gameStarted ? <button id="Enter" type="submit" onClick={() => { handleJoinLobby(x.code) }}>Enter</button> : null}</div>)}
              </div>
            </div>
          </>
          :
          <>
            <div>
              <h2>LOBBY {internalLobbyCode}</h2>
            </div>
            <div className="buttons">
              <button id="Ready" type="button" onClick={handleLeaveLobby}>Exit</button>
              {
                users.find(x => x.host)?.clientID === myClientId ?
                  <>
                    <button id="Start" type="button" onClick={handleStella}>Stella</button>
                    <button id="Start" type="button" onClick={() => { handleStella() }}>Stella - New!</button>
                    <button id="Start" type="button" onClick={() => { handleElementsClash() }}>Elements Clash</button>
                  </>
                  :
                  <button id="Ready" type="button" onClick={handleStatusChange}>Ready</button>
              }
            </div>
            <h2>USERS:</h2>
            <div>
              {
                users.map(x => <div key={x.playerId} className={`user${x.myUser ? " my-user" : ""}${x.host ? " host" : ""}${x.ready ? " ready" : ""}`}>{x.playerId} - {x.nickname}</div>)
              }
            </div>

          </>
      }
    </div>
  )
}

export default observer(Lobby);