export interface ICard {
  name: string;
  type: CardType;
  power?: number;
  elementType?: ElementType;
  artwork?: string; /* Initialized after the deck is loaded */
  alt: string[] /* Array containing alternative artwork for each card in the game, at the beginning when the deck is initalized, for each card an artwork is chosen from it's alternative artwork list */;
  /* Extends here to add new property, effects, etc... */
}

export enum ElementType {
  fire = 1,
  water,
  thunder,
  wind,
  earth,
}

export enum CardType {
  creature = 1,
  /* Extends here to add new card type */
}

export interface IRound {
  title: string,
  result?: Result;
}

export enum Result {
  win,
  lose,
  draw,
}

export interface IField {
  playerField: ICard[];
  otherPlayerField: ICard[];
}

export const roundDuration = 5;