import { observer } from "mobx-react-lite";
import { useState, useRef, useEffect, CSSProperties } from "react";
import { invertColor } from "../../functions/utility";
import { useStore } from "../../hooks/usestore";
import { IMessage } from "../../hooks/usewebsocket";
import { IChatMessage } from "../../models/stella";
import { ReactComponent as ChatIcon } from "../../assets/icons/stella/chat.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";

const Chat = observer(() => {
  const { lobbyStore: { lobbyCode }, stellaStore: { myPlayer, players } } = useStore();
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [newMessages, setNewMessages] = useState(0);
  const [slideIn, setSlideIn] = useState(false);
  const textarea = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    if (window.subscribeMessage) {
      window.subscribeMessage("chat", "chat_message", handleChatMessage);
    }
    return () => {
      if (window.unSubscribeMessage) {
        window.unSubscribeMessage("chat", "chat_message")
      }
    }
  }, [myPlayer, players])

  // Message to send:
  // Send a chat message to other clients
  function sendChatMessage() {
    let message: IMessage = {
      lobbyCode: lobbyCode,
      type: "message_stella",
      value: textarea.current.textContent,
    }
    window.sendWsMessage(message);
    // Clear text area
    textarea.current.textContent = "";
  }

  // Message received:
  // Handle chat messages
  function handleChatMessage(message: IMessage) {
    if (!slideIn) setNewMessages(prev => prev + 1);
    setMessages(prev => {
      let upd = [...prev];
      upd.push(message.value);
      return upd;
    });
  }

  function onTextChange(elem: HTMLDivElement) {
    elem.textContent = elem.innerText;

    // Handle cursor to have it at the end of the string
    const target = document.createTextNode('');
    elem.appendChild(target);
    const isTargetFocused = document.activeElement === elem;
    if (target !== null && target.nodeValue !== null && isTargetFocused) {
      var sel = window.getSelection();
      if (sel !== null) {
        var range = document.createRange();
        range.setStart(target, target.nodeValue.length);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
      if (elem instanceof HTMLElement) elem.focus();
    }
  }

  return (
    <div className="stella-chat">
      <ChatIcon className="icon" onClick={() => { setSlideIn(true); setNewMessages(0); }} />
      <div className="counter" onClick={() => { setSlideIn(true); setNewMessages(0); }}><span>{newMessages}</span></div>
      <div className={`stella-chat-modal${slideIn ? " slide-in" : ""}`}>
        <div className="inner">
          <CloseIcon className="close-icon" onClick={() => setSlideIn(false)} />
          <div className="messages" onWheel={(e) => e.stopPropagation()}> {messages.map((x, index) => <Message key={index} message={x} myPlayerID={myPlayer?.playerID} />)} </div>
          <div className="textarea">
            <div className="input" ref={textarea} contentEditable
              onBlur={(e) => { onTextChange(e.target as HTMLDivElement) }}
              onKeyDown={(e) => { if (e.key === "Enter") { e.preventDefault(); sendChatMessage(); } onTextChange(e.target as HTMLDivElement) }}
              onKeyUp={(e) => { onTextChange(e.target as HTMLDivElement) }}></div>
            <SendIcon className="send" onClick={() => sendChatMessage()} />
          </div>
        </div>
      </div>
    </div>
  )
})

export default Chat;

interface IMessageProps {
  message: IChatMessage,
  myPlayerID?: string
}

const Message = ({ message, myPlayerID }: IMessageProps) => {
  const { stellaStore: { players } } = useStore();
  const [nickname, setNickname] = useState("");
  const [color, setColor] = useState("#1e1e1e");

  useEffect(() => {
    let player = players.find(x => x.playerID === message.playerID);
    if (player) {
      setNickname(player.nickname);
      setColor(player.color)
    }
  }, [players])

  return (
    <div className={`message${message.playerID === myPlayerID ? " mine" : ""}`} style={{ "--player-color": color, "--player-text-color": invertColor(color, true) } as CSSProperties}>
      <h3>{nickname}</h3>
      <div className="text">{message.message}</div>
    </div>
  )
}
