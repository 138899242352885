import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { callEvent } from "../hooks/useeventlistener";
import { useStore } from "../hooks/usestore";
import { IMessage } from "../hooks/usewebsocket";
import { CharacterType } from "../models/character";
import "../styles/containers/interface.scss";

const Interface = () => {
    // const { playerStore: { playerList }, lobbyStore: { lobbyCode, lobbyUsers, setLobbyUsers, currentPlayer, setCurrentPlayer } } = useStore();
    const { playerStore: { playerList }, lobbyStore: { lobbyCode, lobbyUsers, setLobbyUsers } } = useStore();
    const currentPlayer: any = null;
    const setCurrentPlayer = (params: any) => { }

    useEffect(() => {
    }, [lobbyUsers])

    return (
        <div className="ui-container">
            <div className="users">
                {
                    lobbyUsers && lobbyUsers.length > 0 ?
                        lobbyUsers.map(x => {
                            return <div key={x.clientID} className={`player${currentPlayer?.playerId === x.playerId ? " active" : ""}`}>{x.nickname ? x.nickname : x.clientID}</div>
                        })
                        :
                        null
                }
                <button onClick={() => {
                    setLobbyUsers([...lobbyUsers, {
                        clientID: `Test_${lobbyUsers.length + 1}`,
                        nickname: "Test",
                        characters: [
                            { type: CharacterType.Warrior, avaiableActions: [] }
                        ],
                        playerId: `P${lobbyUsers.length + 1}`,
                        color: "pink",
                        myUser: true,
                    }])
                    setCurrentPlayer(`P${lobbyUsers.length + 1}`);
                }}>Add player</button>
            </div>
            <div className="actions">
                {
                    currentPlayer && currentPlayer.myUser ?
                        <>
                            <button onClick={() => { callEvent("onMove") }} >Move</button>
                            <button onClick={() => { callEvent("onAttack") }} >Attack</button>
                            <button onClick={() => {
                                let index = lobbyUsers.findIndex(x => x.playerId === currentPlayer.playerId);
                                if (index > -1) {
                                    index++;
                                    if (index === lobbyUsers.length) { index = 0; }
                                    let message: IMessage = {
                                        lobbyCode: lobbyCode,
                                        type: "set_current_player",
                                        value: {
                                            id: lobbyUsers[index].playerId,
                                        }
                                    }
                                    window.sendWsMessage(message);
                                    setCurrentPlayer(lobbyUsers[index].playerId)
                                }
                            }} >End turn</button>
                        </>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default observer(Interface);