import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useStore } from "../hooks/usestore";
import useWebSocket, { IMessage } from "../hooks/usewebsocket";
import "../styles/components/websocketmanager.scss";

interface ISubscription {
    id: string,
    type: string,
    callbackFn: (message: IMessage) => void,
}

const WebsocketManager = () => {
    const { lobbyStore: { setMyClientId, lobbyCode, setLobbyCode }, webSokcetStore: { setIsConnected } } = useStore();
    const { isConnected, status, sendMessage } = useWebSocket(process.env.REACT_APP_WS_URL || "ws://localhost:8999/", (message) => {
        console.log("message received", message);
        if (message.type === "connected") {
            setMyClientId(message.value)
            let foundClientID = localStorage.getItem("currentClientID");
            if (foundClientID) localStorage.setItem("previousClientID", foundClientID)
            localStorage.setItem("currentClientID", message.value);
            let lobbyCode = localStorage.getItem("lobbyCode");
            if (lobbyCode) setLobbyCode(lobbyCode);
        }
        subscriptions.current.filter(x => x.type === message.type).forEach(x => {
            x.callbackFn(message);
        })
    });

    const subscriptions = useRef<ISubscription[]>([]);

    useLayoutEffect(() => {
        window.sendWsMessage = sendMessage;
        window.subscribeMessage = subscribeMessage;
        window.unSubscribeMessage = unSubscribeMessage;
        return () => {
            window.sendWsMessage = () => { }
            window.subscribeMessage = () => { }
            window.unSubscribeMessage = () => { }
        }
    }, [sendMessage])

    useEffect(() => {
        setIsConnected(isConnected);
    }, [isConnected])

    useEffect(() => {
        window.addEventListener('unload', handleTabClosing)
        return () => {
            window.removeEventListener('unload', handleTabClosing)
        }
    }, [lobbyCode])

    function subscribeMessage(id: string, type: string, callbackFn: (message: IMessage) => void) {
        subscriptions.current.push({
            id: id,
            type: type,
            callbackFn: callbackFn
        })
    }

    function unSubscribeMessage(id: string, type: string) {
        let index = subscriptions.current.findIndex(x => x.id === id && x.type === type);
        if (index > -1) {
            subscriptions.current.splice(index, 1);
        }
    }

    const handleTabClosing = useCallback(() => {
        if (lobbyCode) {
            setLobbyCode(undefined);
            let message: IMessage = {
                lobbyCode: lobbyCode,
                type: "leave_lobby",
            }
            window.sendWsMessage(message);
        }
    }, [lobbyCode])

    return (
        <div className="websocket-container">
            {isConnected ? "Connected" : "Not connected"}
        </div>
    )
}

export default observer(WebsocketManager);