import { observer } from "mobx-react-lite";
import RoundInfo from "./roundinfo";
import Steps from "./steps";
import Word from "./word";
import "../../styles/games/stella/rounds.scss";
import { ReactComponent as ArrowIcon } from "../../assets/icons/stella/arrow.svg";

const Rounds = observer(() => {
  return (
    <>
      {/* <div className="rounds-anchor">
        <ArrowIcon />
      </div> */}
      <div className="rounds">
        <Word />
        <RoundInfo />
        <Steps />
      </div>
    </>
  )
})

export default Rounds;