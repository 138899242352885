import { action, makeObservable, observable } from "mobx";
import { INode, IPosition, MovementTile } from "../models/map";

const maxWalkableTileNum = MovementTile.empty;

// export interface ICell {
//     tile: MovementTile,
//     x: number,
//     z: number,
// }

export default class MapStore {
    map: MovementTile[][] = [];
    mapSize: number = 0;
    constructor() {
        makeObservable(this, {
            map: observable,
            createMap: action,
            updateObjectPos: action,
            findPath: action,
        });
    }
    createMap = (map: MovementTile[][]) => {
        this.map = map;
    }
    updateObjectPos = (currentPos: IPosition, finalPos: IPosition) => {
        this.map[currentPos.x][currentPos.z] = MovementTile.empty;
        this.map[finalPos.x][finalPos.z] = MovementTile.character;
    }
    getPossibleMovements = (start: IPosition, range: number) => {
        let me = this;
        let cells: IPosition[] = [];
        let cellsExclude: IPosition[] = [];
        cells.push(start);
        console.log("start", this.map)
        function recursive(pos: IPosition, range: number) {
            let result = me.nearcells(pos.x, pos.z);
            cellsExclude.push(pos);
            console.log("near me", pos, range, result);
            if (result.length > 0) {
                range--;
                result.forEach(x => {
                    if (cells.findIndex(y => y.x === x.x && y.z === x.z) === -1) {
                        // console.log("current cell", x, range);
                        cells.push(x);
                    }
                    if (cellsExclude.findIndex(y => y.x === x.x && y.z === x.z) === -1 && range > 0) {
                        recursive(x, range);
                    }
                })
            }
        }
        recursive(start, range)
        console.log("end", cells)
        return cells;
    }
    findPath = (start: IPosition, end: IPosition) => {
        var mapWidth = this.map[0].length;
        var mapHeight = this.map.length;
        this.mapSize = mapWidth * mapHeight;
        return this.calculatePath(start, end);
    }
    // Returns every available North, South, East or West
    // cell that is empty. No diagonals,
    // unless distanceFunction function is not Manhattan
    nearcells = (x: number, z: number) => {
        var UP = z - 1,
            DOWN = z + 1,
            RIGHT = x + 1,
            LEFT = x - 1,
            myUP = this.canWalkHere(x, UP),
            myDOWN = this.canWalkHere(x, DOWN),
            myRIGHT = this.canWalkHere(RIGHT, z),
            myLEFT = this.canWalkHere(LEFT, z),
            result: IPosition[] = [];
        if (myUP) result.push({ x: x, z: UP });
        if (myRIGHT) result.push({ x: RIGHT, z: z });
        if (myDOWN) result.push({ x: x, z: DOWN });
        if (myLEFT) result.push({ x: LEFT, z: z });
        // findNeighbours(myN, myS, myE, myW, N, S, E, W, result);
        return result;
    }
    // returns boolean value (world cell is available and open)
    canWalkHere = (x: number, z: number) => {
        return ((this.map[x] != null) &&
            (this.map[x][z] != null) &&
            (this.map[x][z] <= maxWalkableTileNum));
    }
    // Node function, returns a new object with Node properties
    // Used in the calculatePath function to store route costs, etc.
    Node = (parent: INode | null, point: IPosition) => {
        var newNode: INode = {
            // pointer to another Node object
            parent: parent,
            // array index of this Node in the world linear array
            value: point.x + (point.z * this.mapSize),
            // the location coordinates of this Node
            x: point.x,
            z: point.z,
            // point heuristic estimated cost
            // of an entire path using this node
            f: 0,
            // the distanceFunction cost to get
            // from the starting point to this node
            g: 0
        };
        return newNode;
    }
    // Path function, executes AStar algorithm operations
    calculatePath = (start: IPosition, end: IPosition) => {
        // create Nodes from the Start and End x,y coordinates
        var mypathStart = this.Node(null, start);
        var mypathEnd = this.Node(null, end);
        // create an array that will contain all world cells
        var AStar = new Array(this.mapSize);
        // list of currently open Nodes
        var open: INode[] = [mypathStart];
        // list of closed Nodes
        var closed: INode[] = [];
        // list of the final output array
        var result: IPosition[] = [];
        // reference to a Node (that is nearby)
        var myNearCells;
        // reference to a Node (that we are considering now)
        var myNode;
        // reference to a Node (that starts a path in question)
        var myPath;
        // temp integer variables used in the calculations
        var length, max, min, i, j;
        // iterate through the open list until none are left
        while (length = open.length) {
            max = this.mapSize;
            min = -1;
            for (i = 0; i < length; i++) {
                if (open[i].f < max) {
                    max = open[i].f;
                    min = i;
                }
            }
            // grab the next node and remove it from Open array
            myNode = open.splice(min, 1)[0];
            // is it the destination node?
            if (myNode.value === mypathEnd.value) {
                myPath = closed[closed.push(myNode) - 1];
                do {
                    result.push({ x: myPath.x, z: myPath.z });
                }
                while (myPath = myPath.parent);
                // clear the working arrays
                AStar = closed = open = [];
                // we want to return start to finish
                result.reverse();
            }
            else // not the destination
            {
                // find which nearby nodes are walkable
                myNearCells = this.nearcells(myNode.x, myNode.z);
                // test each one that hasn't been tried already
                for (i = 0, j = myNearCells.length; i < j; i++) {
                    myPath = this.Node(myNode, myNearCells[i]);
                    if (!AStar[myPath.value]) {
                        // estimated cost of this particular route so far
                        myPath.g = myNode.g + manhattanDistance(myNearCells[i], myNode);
                        // estimated cost of entire guessed route to the destination
                        myPath.f = myPath.g + manhattanDistance(myNearCells[i], mypathEnd);
                        // remember this new path for testing above
                        open.push(myPath);
                        // mark this node in the world graph as visited
                        AStar[myPath.value] = true;
                    }
                }
                // remember this route as having no more untested options
                closed.push(myNode);
            }
        } // keep iterating until the Open list is empty
        return result;
    }
}

// Utilities function

function manhattanDistance(start: IPosition, end: IPosition) {
    return Math.abs(start.x - end.x) + Math.abs(start.z - end.z);
}