import { observer } from "mobx-react-lite"
import { useCallback, useEffect } from "react";
import { useStore } from "../hooks/usestore";
import { IMessage } from "../hooks/usewebsocket";

// 
const GameHelper = () => {
    const { canvasStore: { elements }, lobbyStore: { lobbyCode, lobbyUsers } } = useStore();
    // const { canvasStore: { elements }, lobbyStore: { lobbyCode, lobbyUsers, currentPlayer, setCurrentPlayer } } = useStore();
    const currentPlayer: any = null
    const setCurrentPlayer = (props: any) => { }

    useEffect(() => {
        console.log("subscribe?", window.subscribeMessage)
        if (window.subscribeMessage) {
            window.subscribeMessage("gamehelper", "character_moved", handleMoveCharacter);
            window.subscribeMessage("gamehelper", "update_current_player", handleUpdateCurrentPlayer);
        }
        return () => {
            if (window.unSubscribeMessage) {
                window.unSubscribeMessage("gamehelper", "character_moved")
                window.unSubscribeMessage("gamehelper", "update_current_player")
            }
        }
    }, [])

    function handleMoveCharacter(message: IMessage) {
        const playerObj = elements.find(x => x.id === message.value.id)
        if (playerObj) {
            playerObj.obj.onMove(message.value.position);
        }
    }

    function handleUpdateCurrentPlayer(message: IMessage) {
        console.log("Update current player", message, lobbyUsers)
        const user = lobbyUsers.find(x => x.playerId === message.value.id)
        if (user) { setCurrentPlayer(user.playerId); }
    }

    // const handleUpdateCurrentPlayer2 = useCallback((message: IMessage) => {
    //     console.log("Update current player 2", message, lobbyUsers)
    //     const user = lobbyUsers.find(x => x.id === message.value.id)
    //     if (user) { setCurrentPlayer(user.playerId); }
    // }, [lobbyUsers])

    return (
        <></>
    );
}

export default observer(GameHelper);